import { useContext, useRef } from 'react'
import { useElementDesign } from '../useElementDesign'
import { FormContext } from '../../contexts'
import { Signature } from "../../components/Signature"
import { useMultiStep } from '../../hooks/useMultiStep'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'
import { useElementStyle } from '../useElementStyle'
import { getDialogValueKey } from '../../utils/features'
import { useDynamicStringReplacement } from '../../hooks/useDynamicStringReplacement'

export default function SignatureElement({ data, process, ...props }) {
  const formContext = useRef()
  const signElement = useRef()
  const inputRef = 'signature'
  const multiStep = useMultiStep(data);
  formContext.current = useContext(FormContext);
  
  const { textStyle } = useElementStyle(data);
  const elementDesign = useElementDesign(data.id, data)
  const labelStyle = textStyle(data?.labelStyle)


  const onUpdate = (newValue) => {
    if (formContext.current) {
      formContext.current.updateValue(getDialogValueKey(data), newValue)
    }
  }

  const onNameChange = (e) => {
    if (formContext.current) {
      formContext.current.updateSignatureName(getDialogValueKey(data), e.target.value)
    }
  }

  const inputErrorClasses = elementDesign.inputErrorClasses(data.requiredField, formContext.current?.inputValues[getDialogValueKey(data)]?.value)

  const borderWidthStyle = data?.borderWidth ? `${data.borderWidth}` : 'inherit'
  const borderColorStyle = data?.borderWidth ? `${data.borderColor}` : 'inherit'
  const heightStyle = data?.height ? `${data.height}px` : 'auto'

  const formNameValue = formContext.current?.inputValues[getDialogValueKey(data)]?.signatureName || data.placeholder;
  const textWithReusableValues = useDynamicStringReplacement(formNameValue, formContext?.current?.reusableProperties, formContext?.current?.dialogValues?.dataTableValues);  

  return (
    <InlineActionbarElementWrapper designElement={elementDesign}>
      <div id={data.id}>
        <Signature
          ref={signElement}
          id={formContext.current ? getDialogValueKey(data) : data.id}
          name={data.placeholder}
          label={elementDesign.translateTerm(data.value)}
          labelStyle={labelStyle}
          helpTooltip={elementDesign.translateHelpText(data)}
          value={formContext.current?.inputValues[getDialogValueKey(data)]?.value}
          readOnly={elementDesign.isReadOnly() || multiStep.isReadOnly}
          onClick={elementDesign.onClick}
          onBlur={() => elementDesign.handleBlur(formContext.current?.inputValues[getDialogValueKey(data)]?.value, inputRef)}
          onUpdate={onUpdate}
          required={data.requiredField}
          borderWidthStyle={borderWidthStyle}
          borderColorStyle={borderColorStyle}
          heightStyle={heightStyle}
          containerClassName={`${elementDesign.elementsClasses()} ${elementDesign.readOnlyElementClasses()} ${inputErrorClasses}`}
          canvasClassName={`${inputErrorClasses} ? ${inputErrorClasses} : null`}
          data={data}
          onNameChange={onNameChange}
          formNameValue={formNameValue ? textWithReusableValues : ''}
          clearSignatureText={elementDesign.translateTerm("Clear signature", "description")}
        />
      </div>
      {elementDesign.errorMessage()}
    </InlineActionbarElementWrapper>
  )
}