import React, { useState, useContext, useRef, useEffect } from 'react'
import * as _ from 'lodash'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import { useElementDesign } from '../useElementDesign'
import { useSelector } from 'react-redux';
import { DialogModes, ElementContext, FormContext } from '../../contexts';
import { setProperty } from '../../utils/property';
import "./styles.scss";
import RichTextWrapper from '../../components/RichText';
import { OpenElementProperties } from '../../utils/elements'
import { getDialogValueKey } from '../../utils/features';
import { useMultiStep } from '../../hooks/useMultiStep';
import { useElementStyle } from '../useElementStyle';

const defaultRichTextData = {
  type: 22,
  property: 'Logo',
  value: 'no value',
  placeholder: 'Metaforce logo',
  layout: {
    order: 5,
  },
  styles: {
    borderWidth: 0
  }
}

export default function RichText({ data, columnWidth = 600, ...props }) {
  data = data || defaultRichTextData
  const formContext = useContext(FormContext);
  const [clonedData, setSetData] = useState(_.cloneDeep(data));
  const formContentInHtml = formContext && formContext.inputValues[getDialogValueKey(data)]?.value
  const [contentInHtml, setContentInHtml] = useState(formContentInHtml ? formContentInHtml : data.richTextValues.contentInHtml); //useState(formContext && !data?.richTextValues?.enableUserInput ? formContentInHtml : data.richTextValues.contentInHtml);
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)
  const dialogDefinitionRef = useRef()
  const elementDesign = useElementDesign(data.id, data)
  const elementContext = useContext(ElementContext);
  const { isEditMode, isPdfMode } = useContext(DialogModes);
  const enableUserInput = data?.richTextValues?.enableUserInput;
  const multiStep = useMultiStep(data);
  const isReadOnly = elementDesign.isReadOnly() || multiStep.isReadOnly;
  const actualValue = formContentInHtml ? formContentInHtml : contentInHtml;
  const elementStyle = useElementStyle(data);
  const { width } = elementStyle.borderAndWidthStyle();
  const height = data?.height;
  const maxWidth = columnWidth;

  useEffect(() => {
    // a bit of a hack to get around closure issues. If dialogDefinitionis used in 
    // handleOnChangeContent it won't always be the most up to date dialog definition
    // which can cause save issues that will undo recent work
    dialogDefinitionRef.current = dialogDefinition
  }, [dialogDefinition])

  const onChangeInEditMode = (value) => {
    const name = "richTextValues.contentInHtml"
    const type = "text"
    const customEventObject = {
      target: {
        name: name,
        value
      }
    };

    elementContext.actions.handleChange(dialogDefinitionRef.current, customEventObject, data.id, type)
    setProperty(clonedData, name, value, type)
    setSetData(clonedData => ({
      ...clonedData
    }));
    setContentInHtml(value);
  } 

  const onChangeFormContext = (value) => {
    formContext?.updateValue(getDialogValueKey(data), value, null, true)
    formContext?.reusablePropertyChanged(getDialogValueKey(data), value)
  }

  const handleOnChangeContent = (value) => {
    if(isEditMode) onChangeInEditMode(value);
    else if(!isEditMode && enableUserInput && formContext) onChangeFormContext(value);
  };

  const contentInHtmlWithTranslation = () => {
    const translatedData = elementDesign.translateRichText(actualValue)
    return translatedData;
  }

  return (
    (!isEditMode && !enableUserInput) || (isReadOnly) ?
      (
        <div dangerouslySetInnerHTML={{ __html: contentInHtmlWithTranslation() }} className={`ql-editor editor-id-${data?.id} hola`} 
          style={{ backgroundColor: '#fff', padding: '5px', fontSize: '13px', fontFamily: 'sans-serif', height: isPdfMode ? 'max-content' : height, width, maxWidth: maxWidth}} />
      )
      :
      (
        <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
          <RichTextWrapper
            id={data.id}
            value={actualValue}
            handleOnChangeContent={handleOnChangeContent}
            isPdfMode={isPdfMode}
            elementsClasses={elementDesign.elementsClasses()}
            onInputClicked={(e) => OpenElementProperties(e, elementDesign.onClick)}
            disabled={isReadOnly}
            height={data?.height}
            width={data?.width > maxWidth ? maxWidth : data?.width}
          />
        </InlineActionbarElementWrapper>
      )
  )
}
