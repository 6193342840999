import React from 'react'
import { useDefinitionManager } from "../screens/Admin/DialogDesign/useDefinitionManager";
import { useSelector } from 'react-redux';
import { RowContext, ContainerLockedContext } from "../contexts";
import { Locked } from '../structures/Row/locked';
import Properties from '../structures/Row/properties';

export const useRowDesign = (rowId, data) => {
  const rowContext = React.useContext(RowContext);
  const containerLockedContext = React.useContext(ContainerLockedContext);

  const isContextActive = rowContext && rowContext.enabled
  const isLocked = containerLockedContext?.isLocked
  return {
    isActive: isContextActive,
    setCurrent: () => {
      rowContext.setCurrent(rowId, data)
    },
    onClick: (evt) => {
      if (isContextActive) {
        try {
          // prevent default event behavior if there is any
          evt.preventDefault()
        }
        catch (err) {
          // do nothing
        }

        if (isLocked) {
          rowContext.showPropertiesPanel(rowId, data, isLocked, (<Locked />));
        }
        else {
          rowContext.showPropertiesPanel(rowId, data, isLocked, (<RowWrapper rowId={rowId} renderRowProperties={(row) => <Properties initData={row} actions={rowContext.actions} />} />));;
        }
      }
    },
    isSelected: () => {
      return rowContext?.currentRowId === rowId
    },
    rowsClasses: (backgroundColor) => {
      let classes = ''

      if (rowContext?.showGrid && rowContext?.showRow) {
        classes = 'grid-row '
      }

      const isNew = rowContext?.newRowId === rowId;

      if (isContextActive || isNew) {
        const isSelected = rowContext?.currentRowId === rowId;

        classes += 'design-row pointer ';
        classes += !backgroundColor ? ' background ' : ''
        classes += isSelected ? ' active ' : ''
        classes += isNew ? ' new ' : ''
        classes += ` open-row-actionbar-${rowId}`
      }

      return classes
    },
    hasContext: () => {
      return isContextActive;
    }
  }
}

const RowWrapper = ({ renderRowProperties, rowId }) => {
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current);
  const definitionManager = useDefinitionManager();
  const row = definitionManager.findRow(dialogDefinition, rowId)
  const rowProperties = renderRowProperties(row)
  return (
    <>
      {rowProperties}
    </>
  )
}