import { useState, useContext, useEffect, useRef } from 'react'
import { DialogModes, FormContext } from '../../contexts'
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import './styles.scss'
import { useElementDesign } from '../useElementDesign';
import { useMultiStep } from '../../hooks/useMultiStep';
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import { useElementStyle } from '../useElementStyle';
import InlineEditing from '../../components/InlineEditing';
import { getDialogValueKey } from '../../utils/features';
import { useDateFormatter } from '../../hooks/useDateFormatter';

export default function DatePicker({ data, ...props }) {
  const formContext = useContext(FormContext);
  const { isEditMode, isPdfMode } = useContext(DialogModes);
  const elementDesign = useElementDesign(data.id, data);
  const [value, setValue] = useState();
  const [show, setShow] = useState(false); 
  const [firstRender, setFirstRender] = useState(true); 
  const multiStep = useMultiStep(data);
  const dateFormatter = useDateFormatter().dateFormatter;
  let inputType = 'text'
  const inputRef = 'date'

  const pickerRef = useRef();

  useEffect(() => {
    if(formContext?.inputValues[getDialogValueKey(data)]?.value)
      formContext?.reusablePropertyChanged(data.property, dateFormatter(formContext?.inputValues[getDialogValueKey(data)]?.value))
  }, [])

  useEffect(() => {
    if(!show && !firstRender){
      elementDesign.handleBlur(formContext?.inputValues[getDialogValueKey(data)]?.value, inputRef)
    }
    setFirstRender(false)
  }, [show])

  function onClick(e) {
    if (!isEditMode && !multiStep.isReadOnly) {
      setShow(!show);
    }
  }

  function onSelected(e) {
    setValue(e)
    formContext?.updateValue(getDialogValueKey(data), e)
    formContext?.reusablePropertyChanged(data.property, dateFormatter(e))
    setShow(false)
  }

  const onClear = () => {
    setValue(null)
    formContext?.updateValue(getDialogValueKey(data), null)
    formContext?.reusablePropertyChanged(data.property, '')
    setShow(false)
  }

  const classPrefix = "datePicker"
  const elementStyle = useElementStyle(data);

  const { borderColor, borderWidth, width } = elementStyle.borderAndWidthStyle();
  const { justifyContent, ...fontStyles } = elementStyle.textStyle(data?.text, data?.text?.alignment);
  const labelStyle = elementStyle.textStyle(data?.labelStyle, data?.text?.alignment);
  const labelClass = elementStyle.textClassName(data?.labelStyle);

  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''
  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : '#fff'

  const datePickerStyle = {
    ...fontStyles,
    borderWidth: 0,
    backgroundColor: backgroundColorStyle,
  }

  const val = formContext?.inputValues[getDialogValueKey(data)]?.value && new Date(formContext?.inputValues[getDialogValueKey(data)]?.value)?.toString() !== "Invalid Date"
    ? new Date(formContext?.inputValues[getDialogValueKey(data)]?.value)
    : value

  const inputErrorClasses = elementDesign.inputErrorClasses(data.requiredField, val)

  const inputWrapperStyle = {
    borderWidth: !inputErrorClasses ? borderWidth : undefined,
    borderColor: !inputErrorClasses ? borderColor : undefined,
    backgroundColor: backgroundColorStyle,
    width: '100%',
    maxWidth: width
  }

  const inputWrapperPdfStyle = {
    width: '100%',
    maxWidth: width
  }

  const currentYear = new Date().getFullYear();
  const fromYear = currentYear - 150;
  const toYear = 2100;

  const hideElement = elementDesign.hideConnectedElement(data?.connectedElement?.value, data?.connectedElement?.type)

  return (
    <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
      <div
        className={`${hideElement && !isEditMode ? 'hidden' : ''} ${data?.labelTop ? "" : "sm:grid sm:grid-cols-2 sm:gap-6 sm:items-start print:grid print:grid-cols-2 print:items-start"}`}
        ref={pickerRef}
        id={data.id}
        onBlur={() => elementDesign.handleBlur(formContext?.inputValues[getDialogValueKey(data)]?.value, inputRef)}
      >
        <InlineEditing
          initData={data}
          style={labelStyle}
          classes={`sf-date-picker-label block text-sm text-gray-700 sm:mt-px sm:pt-2 ${labelClass}`}
          name='label'
          id={`label-${data.id}`}
        >
          <label style={{ wordBreak: 'break-word', ...labelStyle }} className={`sf-date-picker-label block text-sm text-gray-700 sm:mt-px sm:pt-2 ${labelClass}`}>
            {elementDesign.translateTerm(data.label)}
            {elementDesign.requiredFieldIndicator(data.requiredField)}
            {elementDesign.translateHelpText(data)}
          </label>
        </InlineEditing>
        {
          isPdfMode ?
          <div className='sm:mt-0 print:mt-0 max-w-xl w-full' style={inputWrapperPdfStyle}>
            <label className='element block max-w-xl w-full' style={fontStyles}>{val?.toLocaleDateString("en-GB") || "\u00A0"}</label>
          </div>
          :
          <div
            className={`sf-date-picker-input-wrapper sm:mt-0 print:mt-0 max-w-xl w-full ${(elementDesign.isReadOnly() || multiStep.isReadOnly) ? '' : 'cursor-pointer'} ${roundedCornerClass} ${inputErrorClasses} ${elementDesign.readOnlyElementClasses()} print:hidden`}
            style={inputWrapperStyle}
          >
            <div className={`relative ${elementDesign.isReadOnly() ? '' : 'cursor-pointer'}`}>
              <div className={`absolute inset-y-0 left-0 pl-1 flex items-center ${isPdfMode ? 'hidden' : ''}`}>
                <span style={fontStyles} className="sf-date-picker-icon text-gray-500 sm:text-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </span>
              </div>
              <input
                type={inputType}
                id={data.property}
                maxLength={data?.maxLength ?? 999}
                value={val?.toLocaleDateString("en-GB") || ''}
                placeholder={elementDesign.translateTerm(data.placeholder, 'placeholder')}
                onClick={onClick}
                className={`sf-date-picker-input element block max-w-xl w-full pl-9 ${isPdfMode ? '' : 'pl-12'} ${elementDesign.isReadOnly() ? '' : 'cursor-pointer'} ${roundedCornerClass} ${classPrefix} ${elementStyle.prefixClassName(classPrefix)}`}
                style={datePickerStyle}
                disabled={elementDesign.isReadOnly() || multiStep.isReadOnly}
                readOnly={isEditMode}
              />
              {
                data?.trailingText &&
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span style={fontStyles} className="text-gray-500 sm:text-sm">
                    {elementDesign.translateTerm(data.trailingText, 'trailingText')}
                  </span>
                </div>
              }
            </div>
          </div>
        }
        {elementDesign.errorMessage()}
      </div>
      {
        show &&
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-center sm:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClick}></div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <DayPicker
                mode="single"
                fromYear={fromYear}
                toYear={toYear}
                defaultMonth={val ? new Date(val) : new Date()}
                onSelect={onSelected}
                captionLayout="dropdown"
                components={{
                  Footer: () => <DatePickerFooter onClearClick={onClear} />
                }}
              />
            </div>
          </div>
        </div>
      }
    </InlineActionbarElementWrapper>
  )
}

const DatePickerFooter = ({ onClearClick }) => {
  return (
    <div onClick={onClearClick} className={'cursor-pointer hover:underline'}>
      Clear
    </div>
  )
}