import { v4 as uuidv4 } from "uuid";

export const KYC5TaxInformation = () => {
  return {
    name: "KYC - 5 - Tax Information",
    container: {
      id: uuidv4(),
      dialogObjectId: "67372d595b307343c4f36cc0",
      lockToDialogObject: false,
      space: null,
      backgroundColor: "",
      margin: "20px 0",
      padding: null,
      borderWidth: null,
      borderColor: null,
      roundedCorners: false,
      static: false,
      name: "Tax details",
      newPage: false,
      backgroundImage: null,
      backgroundSize: 0,
      backgroundRepeat: false,
      avoidBreakInside: false,
      rows: [
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 0,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 18,
                    bold: true,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "s10a-title-1-1",
                  value: "Tax information",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 3,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: "#f5f5f5",
          margin: "5px 0",
          padding: "10px",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 15,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 5,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 14,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: {
                    value: "none",
                    type: 0
                  },
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "TaxDetailsCountry",
                  value: null,
                  placeholder: "Nothing selected",
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: null,
                  height: null,
                  label: "Country of registration",
                  labelSetManually: true,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: [
                    "Value 1",
                    "Value 2",
                    "Value 3",
                    "Value 4"
                  ],
                  optionWithValueAlphabeticalOrder: true,
                  optionWithValues: [
                    {
                      id: "08a952de-3115-4a3e-b207-23d79b05a076",
                      display: "Norway",
                      description: "Value 1",
                      value: "Norway"
                    },
                    {
                      id: "64387a20-43ba-484d-82d8-83b5870e2718",
                      display: "Sweden",
                      description: "Value 2",
                      value: "Sweden"
                    },
                    {
                      id: "a33d0423-04a8-49ff-9234-a0ce14cbc659",
                      display: "Denmark",
                      description: "Value 3",
                      value: "Denmark"
                    },
                    {
                      id: "2ceb4d24-1952-4ee5-87ec-069d09b1ef47",
                      display: "Finland",
                      description: "Value 4",
                      value: "Finalnd"
                    }
                  ],
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: 10,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 3,
              span: 2,
              margin: "0",
              padding: "0 20px 0 0",
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            },
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 28,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: null,
                  text: null,
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "spacer-53b9",
                  value: null,
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: 10,
                  height: 10,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 0,
              span: 2,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 15,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 5,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 14,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: {
                    value: "none",
                    type: 0
                  },
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "TaxDetailsDomicile",
                  value: null,
                  placeholder: "Nothing selected",
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: null,
                  height: null,
                  label: "Tax domicile",
                  labelSetManually: true,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: [
                    "Value 1",
                    "Value 2",
                    "Value 3",
                    "Value 4"
                  ],
                  optionWithValueAlphabeticalOrder: true,
                  optionWithValues: [
                    {
                      id: "08a952de-3115-4a3e-b207-23d79b05a076",
                      display: "Norway",
                      description: "Value 1",
                      value: "Norway"
                    },
                    {
                      id: "64387a20-43ba-484d-82d8-83b5870e2718",
                      display: "Sweden",
                      description: "Value 2",
                      value: "Sweden"
                    },
                    {
                      id: "a33d0423-04a8-49ff-9234-a0ce14cbc659",
                      display: "Denmark",
                      description: "Value 3",
                      value: "Denmark"
                    },
                    {
                      id: "2ceb4d24-1952-4ee5-87ec-069d09b1ef47",
                      display: "Finland",
                      description: "Value 4",
                      value: "Finalnd"
                    }
                  ],
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: 10,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 3,
              span: 2,
              margin: "0",
              padding: "0 20px 0 0",
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            },
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),                  
                  type: 2,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: {
                    value: "none",
                    type: 0
                  },
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "taxDetailsForeignTIN1",
                  value: "New input text line",
                  placeholder: null,
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: null,
                  height: null,
                  label: "Foreign Tax Identification Number (TIN)",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: "bottom",
              backgroundColor: null,
              space: 0,
              span: 2,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 15,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 5,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 14,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: {
                    value: "none",
                    type: 0
                  },
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "taxDetailsMultiCountryTaxLiability1",
                  value: null,
                  placeholder: "Nothing selected",
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: null,
                  height: null,
                  label: "Parallel tax residence (fill in if the company/association is generally liable for tax in several countries)",
                  labelSetManually: true,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: [
                    "Value 1",
                    "Value 2",
                    "Value 3",
                    "Value 4"
                  ],
                  optionWithValueAlphabeticalOrder: true,
                  optionWithValues: [
                    {
                      id: "08a952de-3115-4a3e-b207-23d79b05a076",
                      display: "Norway",
                      description: "Value 1",
                      value: "Norway"
                    },
                    {
                      id: "64387a20-43ba-484d-82d8-83b5870e2718",
                      display: "Sweden",
                      description: "Value 2",
                      value: "Sweden"
                    },
                    {
                      id: "a33d0423-04a8-49ff-9234-a0ce14cbc659",
                      display: "Denmark",
                      description: "Value 3",
                      value: "Denmark"
                    },
                    {
                      id: "2ceb4d24-1952-4ee5-87ec-069d09b1ef47",
                      display: "Finland",
                      description: "Value 4",
                      value: "Finalnd"
                    }
                  ],
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: 10,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 3,
              span: 2,
              margin: "0",
              padding: "0 20px 0 0",
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            },
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 2,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: {
                    value: "none",
                    type: 0
                  },
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "taxDetailsForeignTIN2",
                  value: "New input text line",
                  placeholder: null,
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: null,
                  height: null,
                  label: "Foreign Tax Identification Number (TIN)",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: "bottom",
              backgroundColor: null,
              space: 0,
              span: 2,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 15,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 5,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 14,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: {
                    value: "none",
                    type: 0
                  },
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "taxDetailsMultiCountryTaxLiabilit2",
                  value: null,
                  placeholder: "Nothing selected",
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: null,
                  height: null,
                  label: "Parallell skatterättslig hemvist (fyll i om bolaget/samfundet är allmänt skattskyldigt i flera länder)",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: [
                    "Value 1",
                    "Value 2",
                    "Value 3",
                    "Value 4"
                  ],
                  optionWithValueAlphabeticalOrder: true,
                  optionWithValues: [
                    {
                      id: "08a952de-3115-4a3e-b207-23d79b05a076",
                      display: "Norway",
                      description: "Value 1",
                      value: "Norway"
                    },
                    {
                      id: "64387a20-43ba-484d-82d8-83b5870e2718",
                      display: "Sweden",
                      description: "Value 2",
                      value: "Sweden"
                    },
                    {
                      id: "a33d0423-04a8-49ff-9234-a0ce14cbc659",
                      display: "Denmark",
                      description: "Value 3",
                      value: "Denmark"
                    },
                    {
                      id: "2ceb4d24-1952-4ee5-87ec-069d09b1ef47",
                      display: "Finland",
                      description: "Value 4",
                      value: "Finalnd"
                    }
                  ],
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: 10,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 3,
              span: 2,
              margin: "0",
              padding: "0 20px 0 0",
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            },
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 2,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: {
                    value: "none",
                    type: 0
                  },
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "taxDetailsForeignTIN3",
                  value: "New input text line",
                  placeholder: null,
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: null,
                  height: null,
                  label: "Foreign Tax Identification Number (TIN)",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: "bottom",
              backgroundColor: null,
              space: 0,
              span: 2,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        }
      ],
      showLogicControl: [],
      hasAutomaticTranslationOff: false,
      resourceId: null,
      readOnly: null
    }
  }
}