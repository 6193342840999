import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { GlobalDndContext } from '../../contexts';
import { ArrowsExpandIcon } from '@heroicons/react/outline';
import { elementTypes } from '../../utils/constants';
import { Columns2, MoveVertical, RectangleHorizontal, Rows3 } from 'lucide-react';
import { useLongPress } from 'react-use';

export const DragHandle = ({
  children,
  isHandleVisible,
  dragHandleProps,
  forSortableType,
  tooltip,
  id
}) => {
  const [longPressedStates, setLongPressedStates] = useState({});
  const { hasActiveDrag, activeDndId, activeFromSidebar } = useContext(GlobalDndContext) || {};
  const isActiveById = activeDndId === id;

  useEffect(() => {
    const handleDocumentClick = (event) => {
      const isInsideComponent = event.target.closest(".drag-handle-container");
      if (!isInsideComponent) {
        handleClickOutside();
      }
    };
  
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    setLongPressedStates((prevState) => {
      const updatedStates = {};
      for (const key in prevState) {
        updatedStates[key] = false;
      }
      return updatedStates;
    });
  }, [id]);
  
  const handleLongPress = () => {
    setLongPressedStates((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };
  
  const handleClickOutside = () => {
    setLongPressedStates((prevState) => {
      const updatedStates = {};
      for (const key in prevState) {
        updatedStates[key] = false;
      }
      return updatedStates;
    });
  };
  
  const handleProps = dragHandleProps ? { ...dragHandleProps } : {};

  const getPositioningClasses = () => {
    switch (forSortableType) {
      case "column":
        return "absolute top-0 -right-8 z-50"
      case "row":
        return "absolute -left-16 h-full"
      case "container":
        return "absolute -right-9 top-10 z-50"
      default:
        return "absolute z-50 -left-7"
    }
  }

  const getWrapperClassName = () => {

    switch (forSortableType) {
      case "element":
        return "flex flex-row"
      case "column":
        return "relative flex-1"
      default:
        return "relative"
    }
  }

  const getDraggingIconBoxSizeClasses = () => {
    switch (forSortableType) {
      case "element":
        return "w-8 h-8"
      case "container":
        return "w-8 h-8"
      default:
        return "w-10 h-10"
    }
  }

  const getDraggingIconSizeClasses = () => {
    switch (forSortableType) {
      case "element":
        return "w-5 h-5"
      case "container":
        return "h-4 w-4"
      default:
        return "h-5 w-5"
    }
  }

  const getDraggeableIconPositionByElementType = () => {
    const elementType = children?.props?.children?.props?.element?.type
    switch(elementType) {
      case elementTypes.table:
        return "mr-8 ml-1"

      default:
        return "mr-1 ml-1"
    }
  }

  const longPressEvents = useLongPress(handleLongPress, { delay: 300 });

  return (
    <div
      className={getWrapperClassName()}
      {...longPressEvents}
    >
      {Object.entries(longPressedStates).map(([id, isLongPressed]) => (
        <React.Fragment key={id}>
          {isHandleVisible && hasActiveDrag() && isActiveById && !activeFromSidebar && (
            <div className={`z-50 ${getPositioningClasses()}`}>
              <div
                className={`absolute -right-7 ignore-style-change bg-white shadow sm:rounded-lg flex justify-around items-center ${getDraggingIconBoxSizeClasses()} text-sm`}
                style={{ cursor: 'grabbing' }}
              >
                <ArrowsExpandIcon
                  className={`transform rotate-45 group-hover:text-gray-400 ${getDraggingIconSizeClasses()}`}
                />
              </div>
            </div>
          )}
          {isHandleVisible && isLongPressed && !hasActiveDrag() && (
            <div className={`${getPositioningClasses()}`}>
              {forSortableType === "container" && (
                <div
                  className="ignore-style-change bg-white rounded border border-gray-300 w-8 h-8 flex items-center justify-center absolute -right-7"
                  style={{ cursor: 'grab' }}
                  title={tooltip}
                  {...handleProps}
                >
                  <MoveVertical className="w-4 h-4" />
                </div>
              )}
              {forSortableType === "row" && (
                <div className="h-full">
                  <div className="m-auto mr-12">
                    <div
                      style={{ cursor: 'grab' }}
                      title={tooltip}
                      {...handleProps}
                    >
                      <div className="ignore-style-change p-1 mb-1 bg-white flex items-center hover:text-gray-600 focus:outline-none border border-gray-300 rounded-md">
                        <Rows3 className="w-6 h-6 text-gray-600" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {forSortableType === "column" && (
                <div
                  style={{ cursor: 'grab' }}
                  title={tooltip}
                  {...handleProps}
                >
                  <div className="ignore-style-change p-1 mb-1 bg-white flex items-center hover:text-gray-600 focus:outline-none border border-gray-300 rounded-md">
                    <Columns2 className="w-6 h-6 text-gray-600" />
                  </div>
                </div>
              )}
              {forSortableType === "element" && (
                <div
                  className={`z-50 ${getDraggeableIconPositionByElementType()} absolute -left-2`}
                  style={{ cursor: 'grab', alignSelf: "center" }}
                  title={tooltip}
                  {...handleProps}
                >
                  <div className="ignore-style-change p-1 mb-1 bg-white flex items-center hover:text-gray-600 focus:outline-none border border-gray-300 rounded-md">
                    <RectangleHorizontal className="w-6 h-6 text-gray-600" />
                  </div>
                </div>
              )}
            </div>
          )}
        </React.Fragment>
      ))}
      {children}
    </div>
  );
};