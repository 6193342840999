import { useContext, useEffect, useState } from 'react'
import { DialogModes, FormContext } from '../../contexts'
import './styles.scss'
import { useElementDesign } from '../useElementDesign';
import { useMultiStep } from '../../hooks/useMultiStep';
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import { useElementStyle } from '../useElementStyle';
import InlineEditing from '../../components/InlineEditing';
import { getDialogValueKey } from '../../utils/features';
import _ from 'lodash';

export default function RadioList({ data, ...props }) {
  const formContext = useContext(FormContext);
  const [value, setValue] = useState();
  const multiStep = useMultiStep(data);
  const elementStyle = useElementStyle(data);
  const elementDesign = useElementDesign(data.id, data);
  const { isEditMode } = useContext(DialogModes);
  let inputType = 'radio'
  const classPrefix = "inputTextLine"
  const optionsWithValueClone = _.cloneDeep(data?.optionWithValues);

  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''
  const wrapperStyle = elementStyle.borderAndWidthStyle();

  const labelStyle = elementStyle.textStyle(data?.text, 'left');
  const labelClass = elementStyle.prefixClassName(classPrefix);

  const descriptionStyle = elementStyle.textStyle(data?.labelStyle, 'left');
  const descriptionClass = elementStyle.textClassName(data?.labelStyle);

  const inputErrorClasses = elementDesign.inputErrorClasses(data.requiredField, formContext?.inputValues[getDialogValueKey(data)]?.value)

  let currentValue;
  let onChange;
  if (formContext) {
    currentValue = formContext?.inputValues[getDialogValueKey(data)]?.value;
    onChange = (e) => {
      formContext.updateValue(getDialogValueKey(data), e.target.value)
      formContext?.reusablePropertyChanged(data.property, e.target.value)
    }
  } else {
    currentValue = value
    onChange = (e) => {
      setValue(e.target.value)
    }
  }

  if (data?.optionWithValueAlphabeticalOrder) {
    optionsWithValueClone?.sort((a, b) => a?.display?.localeCompare(b?.display))
  }

  useEffect(() => {
    formContext?.reusablePropertyChanged(data.property, currentValue)
  }, [])

  return (
    <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
      <fieldset className={`${elementDesign.readOnlyElementClasses()}`}>
        <InlineEditing
          initData={data}
          style={labelStyle}
          classes={`block text-sm text-gray-700 sm:mt-px sm:pt-2`}
          name='label'
          id={`label-${data.id}`}
        >
          <label style={labelStyle} className={`block text-sm text-gray-700 sm:mt-px sm:pt-2`}>
            {elementDesign.translateTerm(data.label)}
            {elementDesign.requiredFieldIndicator(data.requiredField)}
            {elementDesign.translateHelpText(data)}
          </label>
        </InlineEditing>
        <div
          className={`bg-white rounded-md -space-y-px ${inputErrorClasses}`}
          onChange={onChange}
          id={data.id}
        >

          {
            optionsWithValueClone?.map((option, index) => {
              return (
                <label
                  key={option.id}
                  style={wrapperStyle}
                  className={`relative border p-4 flex ${roundedCornerClass} ${index === 0 && data.roundedCorners ? 'rounded-tl-md rounded-tr-md' : ''} ${index === optionsWithValueClone?.length - 1 && data.roundedCorners ? 'rounded-bl-md rounded-br-md' : ''} ${elementDesign.isReadOnly() ? '' : 'cursor-pointer'}`}
                >
                  <input
                    type={inputType}
                    name={`radio-list-${data.id}`}
                    value={option.value}
                    className={`h-4 w-4 mt-0.5 cursor-pointer border-gray-300 ${inputErrorClasses}`}
                    aria-labelledby="privacy-setting-0-label"
                    aria-describedby="privacy-setting-0-description"
                    checked={currentValue === option.value}
                    onBlur={() => elementDesign.handleBlur(formContext?.inputValues[getDialogValueKey(data)]?.value, inputType)}
                    disabled={elementDesign.isReadOnly() || multiStep.isReadOnly || isEditMode }
                  />
                  <div className="ml-3 flex flex-col">
                    <span id="privacy-setting-0-label" style={labelStyle} className={`${labelClass}`}>
                      {elementDesign.translateOption(option.id, option.display, 'display')}
                    </span>
                    <span id="privacy-setting-0-description" style={descriptionStyle} className={`${descriptionClass}`}>
                      {elementDesign.translateOption(option.id, option.description, 'description')}
                    </span>
                  </div>
                </label>
              )
            })
          }
        </div>
        {elementDesign.errorMessage()}
      </fieldset>
    </InlineActionbarElementWrapper>
  )
}