import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { getTranslationPleaseWait } from '../../utils/systemTranslation';

export default function GenerateLockSmartformDialog({ open, setOpen, languageCode }) {
  const anyRef = useRef(null);

  const handleClose = () => {
    if (!open) {
      setOpen(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="cursor-wait fixed z-10 inset-0 overflow-y-auto" onClose={handleClose} initialFocus={anyRef}>
        <div className="flex items-center justify-center min-h-screen p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div ref={anyRef} className="cursor-default bg-white rounded-lg p-6 shadow-xl transform transition-all sm:max-w-sm w-full">
              <div className="flex justify-center mb-4">
                <svg className="animate-spin h-8 w-8 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
              </div>
              <Dialog.Title as="h3" className="text-lg font-medium text-gray-900 text-center">
                {getTranslationPleaseWait(languageCode)}
              </Dialog.Title>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}