import { v4 as uuidv4 } from "uuid";

export const KYC1Customer = () => {
    return {
    name: "KYC - 1 - Customer",
    container: {
      id: uuidv4(),
      dialogObjectId: "67372f1d5b307343c4f48eac",
      lockToDialogObject: false,
      space: null,
      backgroundColor: "",
      margin: "20px 0",
      padding: null,
      borderWidth: null,
      borderColor: null,
      roundedCorners: false,
      static: false,
      name: "The company/society",
      newPage: false,
      backgroundImage: null,
      backgroundSize: 0,
      backgroundRepeat: false,
      avoidBreakInside: false,
      rows: [
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 0,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 18,
                    bold: true,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "KYC1-title",
                  value: "The company/society",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 3,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: "#f5f5f5",
          margin: "5px 0",
          padding: "10px",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 2,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 14,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: {
                    value: "none",
                    type: 0
                  },
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "companyname",
                  value: "New input text line",
                  placeholder: null,
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: null,
                  height: null,
                  label: "Full name of the company/association",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: true,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 0,
              span: 2,
              margin: "0",
              padding: "0 20px 0 0",
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            },
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 15,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 5,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 14,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: {
                    value: "none",
                    type: 0
                  },
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "dropDown-regCountry",
                  value: null,
                  placeholder: "Nothing selected",
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: null,
                  height: null,
                  label: "Company registered in",
                  labelSetManually: true,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: [
                    "Value 1",
                    "Value 2",
                    "Value 3",
                    "Value 4"
                  ],
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: [
                    {
                      id: "9c1d1dfd-d0a4-406e-bb72-896ac48c6eae",
                      display: "Sweden",
                      description: "Value 1",
                      value: "SE"
                    },
                    {
                      id: "ffbc46d4-900f-4c1f-b90f-17cc9fb73866",
                      display: "Norway",
                      description: "Value 2",
                      value: "NO"
                    },
                    {
                      id: "6465bed9-f21a-4c84-abb7-dccfcdde51fb",
                      display: "Denmark",
                      description: "Value 3",
                      value: "DK"
                    },
                    {
                      id: "00e81dcb-3d7e-4019-a1a4-5819a4da5568",
                      display: "Finland",
                      description: "Value 4",
                      value: "FI"
                    },
                    {
                      id: "7c6054b9-d6d0-4c84-9f19-5ec06f6e8644",
                      display: "Germany",
                      description: "",
                      value: "GE"
                    },
                    {
                      id: "59102d48-f87f-4ea0-b1d8-a0f3e93ac9bc",
                      display: "France",
                      description: "",
                      value: "FR"
                    }
                  ],
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: 10,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: true,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 0,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 2,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: {
                    value: "none",
                    type: 0
                  },
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "inputOrgnummer",
                  value: "New input text line",
                  placeholder: null,
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: 300,
                  height: null,
                  label: "Organization number",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: "67163a8eb5670ebba3910130",
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: true,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 0,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        }
      ],
      showLogicControl: [],
      hasAutomaticTranslationOff: true,
      resourceId: null,
      readOnly: null
    }
  }
}