import { useRef } from "react";
import { ChevronUp, ChevronDown } from "lucide-react";
/**
 * @param {*} Text 
 */
export const Text = ({ onChange, label, name, prefix, suffix, type, value = "", minNumber = 0, maxNumber = 999999, disabled, allowEmptyNumber = false }) => {
  const inputRef = useRef();
  const minNumberVerification = type === "number" && minNumber >= 0;
  const maxNumberVerification = type === "number" && maxNumber >= 0;
  const xmlRegex = /^[a-zA-Z_][a-zA-Z0-9._-]*$/;

  const blockInvalidChar = (e) => {
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  
  if(type === "number"){
    let value = parseInt(e.target.value, 10);
    if (e.key === 'ArrowDown') {
      value -= 1;
      value < minNumber && e.preventDefault();
    } else if (e.key === 'ArrowUp') {
      value += 1
      value > maxNumber && e.preventDefault()
    }    
  }
};

  const handleOnChange = (e, type) => {
    let value = e.target.value;
    let name = e.target.name;
    const regexCheckProps = ['property','radioGroupName'];
    if (regexCheckProps.includes(name)) {
      const regVerif = xmlRegex.test(value);
      if(!regVerif) return e.preventDefault();
    }
    
    switch (type) {
      case 'number':
        if (value < minNumber) e.target.value = minNumber
        if (value > maxNumber) e.target.value = maxNumber
 
        let event = {
          target: {
            name: name,
            value: value,
          }
        }

        if(allowEmptyNumber && value === ''){
          e.target.value = null
          event.target.value = null
          type= "text"
        }
  
        if (!value || (value >= minNumber && value <= maxNumber)) {
          onChange(event, type)
        }
        break;
    
      default:
        onChange(e, type)
        break;
    }
  }

  const onIncrementClick = (e) => {
    e.preventDefault();

    const value = inputRef.current.valueAsNumber
    const newValue = value + 1
    if(newValue <= maxNumber || !maxNumber){
      inputRef.current.value = newValue
      e.target.value = newValue;
      e.target.name = name;
      onChange(e, type)
    }
  }

  const onSubtractClick = (e) => {
    e.preventDefault();

    const value = inputRef.current.valueAsNumber
    const newValue = value - 1
    if (newValue >= minNumber || !minNumber) {
      inputRef.current.value = newValue
      e.target.value = newValue;
      e.target.name = name;
      onChange(e, type)
    }
  }
  return (
    <div className={`px-4 sm:px-6 pt-3 flex flex-col justify-between`}>
      <span className="flex-grow flex flex-col justify-center" id="availability-label">
        <span className="text-sm font-medium text-gray-500">{label}</span>
      </span>
      <div className="mt-1 relative">
        {
          prefix &&
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">
              {prefix}
            </span>
          </div>
        }

        <input
          type={type || "text"}
          name={name}
          value={value}
          disabled={disabled}
          className={`focus:ring-gray-400 focus:border-gray-400 block w-full ${prefix ? 'pl-7 ' : ''} ${suffix ? 'pr-12 ' : ''} sm:text-sm border-gray-300 rounded-md 2xl:min-w-max ${disabled ? 'bg-gray-200' : ''}`}
          onKeyDown={minNumberVerification && maxNumberVerification ? blockInvalidChar : undefined}
          onChange={(e) => handleOnChange(e, type)}
          min={minNumber}
          max={maxNumber}
          ref={inputRef}
        />
        {
          type === "number" &&
            <div className={"input-number-buttons"}>
              <button onClick={(e) => onIncrementClick(e)}>
                <ChevronUp className="w-4 h-4" />
              </button>
              <button onClick={(e) => onSubtractClick(e)}>
                <ChevronDown className="w-4 h-4" />
              </button>
            </div>
        }
        {
          suffix &&
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm" id="price-currency">
              {suffix}
            </span>
          </div>
        }

      </div>
    </div>
  );
}