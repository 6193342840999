import { useClassNames } from '@metaforcelabs/metaforce-core'
import React from 'react'
import { dialogDesignerPageKeys, dialogDesignerPages } from '../../../utils/constants';

export default function DialogPageViewSelect({ activePage, onPageClick, disableDatasets }) {
  
  const pages = [...dialogDesignerPages];
  
  if(disableDatasets){
    pages.splice(pages.findIndex(page => page.key === dialogDesignerPageKeys.data), 1);
  }

  const enabledPages = pages.filter(page => page.featureEnabled);

  return enabledPages.length > 1 && (

    <div>
      <div className='flex space-x-4 justify-center'>
        {
          enabledPages.map((page, index) => {
            return <MiniToggleButton
              key={page.key}
              isActive={activePage === page.key}
              label={page.label}
              onClick={() => onPageClick(page.key)}
            />
          })
        }

      </div>
    </div>
  )
}

const MiniToggleButton = ({ isActive, label, onClick }) => {
  const { classNames } = useClassNames();

  return <button
    type="button"
    className={classNames(isActive ?
      "bg-brand-blue border-transparent text-white" : "bg-white hover:bg-brand-blue border hover:text-white hover:border-transparent border-gray-200 text-gray-500",
      "disabled:opacity-50 ",
      "disabled:cursor-not-allowed justify-center",
      "items-center px-2 py-1 border text-sm",
      "leading-4 font-medium rounded-md focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-300")}
    onClick={onClick}
  >
    {label}
  </button>
}
