import React, { useContext, useMemo, useRef, useState } from 'react'
import './styles.scss'
import * as Elements from "../../elements";
import { useColumnDesign } from '../../elements/useColumnDesign';
import { elementTypes } from '../../utils/constants';
import { ContainerLockedContext, DialogModes, ElementContext } from '../../contexts';
import useClickOutside from '../../hooks/useClickOutside';
import { DndSortable } from '../../components/DragAndDrop/sortable'
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"

export function ElementFromType({ element, dialogData, process, columnWidth, isConnectedElement, isParentReadOnly, ...props }) {
  switch (element.type) {
    case elementTypes.textLine:
      return <Elements.TextLine data={element} />
    case elementTypes.hyperLink:
      return <Elements.HyperLink data={element} />
    case elementTypes.textArea:
      return <Elements.TextArea data={element} />
    case elementTypes.inputTextLine:
      return <Elements.InputTextLine columnWidth={columnWidth} data={element} isConnectedElement={isConnectedElement} isParentReadOnly={isParentReadOnly} />
    case elementTypes.headerLine:
      return <Elements.HeaderLine data={element} />
    case elementTypes.bitmatp:
      return <Elements.Bitmap data={element} />
    case elementTypes.resource:
      return <Elements.Resource data={element} />
    case elementTypes.video:
      return <Elements.Video data={element} />
    case elementTypes.datePicker:
      return <Elements.DatePicker data={element} />
    case elementTypes.button:
      return <Elements.Button data={element} dialogData={dialogData} />
    case elementTypes.switch:
      return <Elements.Switch data={element} />
    case elementTypes.divider:
      return <Elements.Divider data={element} />
    case elementTypes.radioList:
      return <Elements.RadioList data={element} />
    case elementTypes.orderTable:
      return <Elements.OrderTable data={element} />
    case elementTypes.fileUpload:
      return <Elements.FileUpload data={element} />
    case elementTypes.disclaimer:
      return <Elements.Disclaimer data={element} />
    case elementTypes.dropDown:
      return <Elements.Dropdown data={element} />
    case elementTypes.flowSelector:
      return <Elements.FlowSelector data={element} />
    case elementTypes.checkbox:
      return <Elements.Checkbox data={element} />
    case elementTypes.cancelButton:
      return <Elements.CancelButton data={element} />
    case elementTypes.signature:
      return <Elements.Signature process={process} data={element} />
    case elementTypes.timePicker:
      return <Elements.TimePicker data={element} />
    case elementTypes.attachment:
      return <Elements.Attachment data={element} />
    case elementTypes.richText:
      return <Elements.RichText data={element} columnWidth={columnWidth} />
    case elementTypes.dynamicList:
      return <Elements.DynamicList data={element} />
    case elementTypes.radioButtonGroup:
      return <Elements.RadioButtonGroup data={element} />
    case elementTypes.spacer:
      return <Elements.Spacer data={element} />
    case elementTypes.smartTable:
      return <Elements.SmartTable data={element} />
    case elementTypes.matrix:
      return <Elements.Matrix data={element} />
    case elementTypes.map:
      return <Elements.MapElement data={element} />
    default:
      return <></>
  }
}

export const getWrapperClassFromType = (type) => {
  switch (type) {
    case elementTypes.richText:
      return "min-w-full";
    default:
      return "min-w-0 w-full";
  }
}

export default function Column({ data, dialogData, callbackSelectedComponents, ...props }) {
  const classPrefix = "column"
  const { isEditMode, isMobileMode } = useContext(DialogModes)
  const containerLockedContext = useContext(ContainerLockedContext)
  let alignmentClass = "";
  switch (data?.contentAlign) {
    case 'center':
      alignmentClass = "place-content-center";
      break
    case 'right':
      alignmentClass = "justify-end";
      break
    case 'left':
      alignmentClass = "justify-start";
      break
    default:
      alignmentClass = "";
      break
  }


  const ref = useRef();

  const gapClass = data?.space ? `gap-${data.space}` : ''
  const roundedClass = data?.roundedCorners ? 'rounded-md' : ''
  const fullHeight = data?.fullHeight ? 'h-full' : ''


  const borderWidthStyle = data?.borderWidth ? `${data.borderWidth}` : 'inherit'
  const borderColorStyle = data?.borderColor ? `${data.borderColor}` : 'inherit'
  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : 'transparent'

  const marginStyle = data?.margin ? data.margin : 'default'
  const paddingStyle = data?.padding ? data.padding : 'default'

  const style = {
    borderWidth: borderWidthStyle,
    borderColor: borderColorStyle,
    backgroundColor: backgroundColorStyle,
    margin: marginStyle,
    padding: paddingStyle,
    display: data?.hidden ? "none" : undefined
  }

  const elementContext = useContext(ElementContext);
  const columnDesign = useColumnDesign(data.id, data)

  const handleShowProperties = () => {
    columnDesign.onClick()
  }

  const [isModalOpen, setModalOpen] = useState(false);
  useClickOutside(ref, () => setModalOpen(false));

  const close = () => {
    setModalOpen(false);
  }

  const handleColumnClick = () => {
    setModalOpen(columnDesign.isActive)
    columnDesign.onClick();
  }

  const sortableItems = useMemo(() => data.elements || [], [data.elements])

  const getElementFromType = (element) => {
    return <ElementFromType process={elementContext?.process} columnWidth={ref.current?.offsetWidth} dialogData={dialogData} element={element} />
  }

  const editModeAndNotLocked = isEditMode && sortableItems && !containerLockedContext?.isLocked && !isMobileMode;
  return (
    <>
      <div
        ref={ref}
        className={`grid auto-rows-min ${fullHeight}  ${alignmentClass} ${gapClass} ${roundedClass} ${columnDesign.columnsClasses(editModeAndNotLocked)}`}
        style={style} onClick={handleColumnClick}
      >
        {
          editModeAndNotLocked
            ? (
              <SortableContext
                id={`column-sortable-context-${data.id}`}
                items={sortableItems.map(i => `element-${i.id}`)}
                strategy={verticalListSortingStrategy}
              >
                {
                  sortableItems.map(element => {
                    return (

                      <DndSortable
                        key={`element-${element.id}`}
                        type="element"
                        id={`element-${element.id}`}
                        dragHandle={true}
                        dragHandleTooltip="Drag element"
                      >
                        <div className={`${getWrapperClassFromType(element.type)}`}>
                          {!element.hidden && getElementFromType(element)}
                        </div>
                      </DndSortable>

                    )
                  })
                }
              </SortableContext>
            )
            : (
              data.elements && data.elements.map((element, index) => {
                return (
                  <AvoidPageBreakOnPrintWrapper key={`element-${element.id}`} elementType={element.type}>
                    <div className={getWrapperClassFromType(element.type)}>
                      {getElementFromType(element)}
                    </div>
                  </AvoidPageBreakOnPrintWrapper>                  
                )
              })
            )
        }
      </div>
    </>
  )
}

/*
  Derived after reading https://stackoverflow.com/questions/63602216/when-generating-a-pdf-with-puppeteer-break-inside-avoid-is-ignored
  to prevent the element from breaking across a page
*/
const AvoidPageBreakOnPrintWrapper = ({ elementType, children }) => {
  let additionalClasses = '';
  if (elementType === elementTypes.timePicker) {
    // Adding overflow-hidden here somehow nicely causes the overflow of only the inner
    // time input values (hour:minute:am/pm) to be hidden. This is a good thing, but I'm not sure
    // why it does that.
    additionalClasses += ' overflow-hidden'
  }

  return (
    <div className={`block ${additionalClasses}`}>
      <div className='print-break-inside-avoid float-left w-full'>
        {children}
      </div>
      <div style={{ clear: "both" }} />
    </div>
  )
}