import { useContext, useEffect, useRef, useState } from 'react'
import { DialogModes, FormContext } from '../../contexts'
import './styles.scss'
import { useElementDesign } from '../useElementDesign'
import { useMultiStep } from '../../hooks/useMultiStep'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'
import { validateDataTypes } from "../../utils/constants";
import { useElementStyle } from '../useElementStyle'
import SelectCountry from './selectCountry'
import { getInformation, getInformationWorkflow } from '../../api/apiDefinition'
import { useDebounceCallback } from '@react-hook/debounce'
import { useInlineEditing } from '../../hooks/useInlineEditing'
import { useParams } from 'react-router'
import InlineEditing from '../../components/InlineEditing'
import { OpenElementProperties } from '../../utils/elements'
import { getDialogValueKey } from '../../utils/features'

const defaultInputTextLineData = {
  type: 2,
  inputType: 1,
  property: 'Email',
  value: '',
  maxLength: 8,
  placeholder: 'you@example.com',
  layout: {
    order: 4,
  },
  styles: {
    borderWidth: 1
  },
  dependencies: {
    type: 0,
    value: 'none',
  },
  text: {
    alignment: 'left',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
    color: 'grey'
  }
}

export default function InputTextLine({ data, columnWidth = 600, isConnectedElement, isParentReadOnly, ...props }) {
  const formContext = useContext(FormContext);
  const { dialogKey, valuesKey } = useParams();
  const { isPreviewMode, isEditMode, firstRender, isStepByStepMode } = useContext(DialogModes);

  const elementDesign = useElementDesign(data.id, data);
  const multiStep = useMultiStep(data);
  const elementStyle = useElementStyle(data);
  const [countrySelect, setCountrySelect] = useState();
  data = data || defaultInputTextLineData;
  const [inputValue, setInputValue] = useState(data?.initialValue ?? '')

  const classPrefix = "inputTextLine"
  let inputType = inputTypes();
  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''
  const leadingTextClass = data?.leadingText ? `pl-${paddingNumber(data.leadingText)}` : ''
  const trailingTextClass = data?.trailingText ? `pr-${paddingNumber(data.trailingText)}` : ''

  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : '#fff'
  const marginStyle = data?.margin ? data.margin : 'inherit'

  const minValue = data?.validateData === validateDataTypes.number ? data?.minValue : undefined
  const maxValue = data?.validateData === validateDataTypes.number ? data?.maxValue : undefined
  const minLength = data?.validateData === validateDataTypes.text ? data?.minLength : undefined
  const maxLength = data?.validateData === validateDataTypes.text ? data?.maxLength : undefined

  const isLabelHidden = data?.hideLabel ?? false;
  const labelClass = elementStyle.textClassName(data?.labelStyle)
  const labelStyle = elementStyle.textStyle(data?.labelStyle)
  const inputErrorClasses = elementDesign.inputErrorClasses(data.requiredField, formContext?.inputValues[getDialogValueKey(data)]?.value)

  const { borderWidth, borderColor, width: inputWidth } = elementStyle.borderAndWidthStyle();
  const { color, width: textWidth, ...textStyles } = elementStyle.textStyle(data?.text);
  const alignmentClass = elementStyle.flexAlignmentClass(data?.labelStyle?.alignment)

  const inputWrapperStyle = {
    borderWidth: !inputErrorClasses ? borderWidth : undefined,
    borderColor: !inputErrorClasses ? borderColor : undefined,
    backgroundColor: backgroundColorStyle,
    margin: marginStyle,
    color,
    width: inputWidth,
    maxWidth: columnWidth - 1
  }

  const inputStyle = {
    ...textStyles,
    backgroundColor: backgroundColorStyle,
    borderWidth: 0,
    color
  }

  const isReadOnlyDependency = elementDesign.isReadOnlyDependency(data?.dependencies?.value, data?.dependencies?.type);

  const inputRef = useRef();
  const typingTimeoutRef = useRef(null);
  const inputName = isEditMode ? 'initialValue' : '';
  const inputProps = isEditMode ? { onClick: (e) => OpenElementProperties(e, elementDesign.onClick) } : {}
  const { onChangeInlineEditing } = useInlineEditing(data)

  if (isReadOnlyDependency && inputRef.current !== undefined) {
    inputRef.current.value = '';
  }

  useEffect(() => {
    formContext?.reusablePropertyChanged(data.property, formContext?.inputValues[getDialogValueKey(data)]?.value)
    !firstRender && !isStepByStepMode && elementDesign.handleBlur(formContext?.inputValues[getDialogValueKey(data)]?.value, inputType, countrySelect)
  }, []);

  useEffect(() => {
    setInputValue(data.initialValue ?? '')
  }, [data]);

  const getInformationFromWorkflow = useDebounceCallback((value, workflowRetrieveId) => {
    const execute = async () => {
      formContext.onHandleLockSmartform(true);
      var propertyPairs = await getInformationWorkflow(dialogKey, valuesKey, workflowRetrieveId, [{ property: data.property, value: value }])
      formContext?.updateByPropertyNameValuePairs(propertyPairs)
      formContext.onHandleLockSmartform(false);
    }
    execute();
  },
    1000);

  const onChange = async (e) => {
    let value = e.target.value;

    if (data.workflowRetrieveId && formContext){
      if (typingTimeoutRef.current) 
        clearTimeout(typingTimeoutRef.current);
      
      typingTimeoutRef.current = setTimeout(() => {
        getInformationFromWorkflow(value, data.workflowRetrieveId);
      }, 1500);
    }

    if (!formContext)
      setInputValue(value)

    if (isEditMode)
      onChangeInlineEditing(e)

    e.preventDefault();

    switch (data.validateData) {
      case validateDataTypes.number:
        if (isPreviewMode) {
          if (value < minValue) e.target.value = minValue
          if (value > maxValue) e.target.value = maxValue
        }

        const valueWithVerification = !minValue && !maxValue ? value : Math.max(minValue, Math.min(maxValue, Number(value)));
        formContext?.updateValue(getDialogValueKey(data), valueWithVerification.toString())
        break;
      default:
        formContext?.updateValue(getDialogValueKey(data), value)
        break;
    }

    formContext?.reusablePropertyChanged(data.property, value)
  }

  function inputTypes() {
    switch (data?.validateData) {
      case validateDataTypes.text:
        return 'text';
      case validateDataTypes.number:
        return 'number';
      case validateDataTypes.email:
        return 'email';
      default:
        return 'none';
    }
  }

  function paddingNumber(text) {
    let textLength = text.length

    if (textLength < 3)
      return 8;

    if (textLength < 6)
      return 10;

    if (textLength < 8)
      return 16;

    if (textLength < 13)
      return 24;

    if (textLength < 16)
      return 28;

    if (textLength < 20)
      return 32;

    return 40;
  }

  const keyDownValidation = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }

    if (data?.validateData === validateDataTypes.number) {
      let value = parseInt(e.target.value, 10);
      if (e.key === 'ArrowDown') {
        value -= 1;
        value < minValue && e.preventDefault();
      } else if (e.key === 'ArrowUp') {
        value += 1
        if (maxValue !== null && maxValue !== undefined && value > maxValue) {
          e.preventDefault();
        }      }
    }
  }

  const hideElement = elementDesign.hideConnectedElement(data?.connectedElement?.value, data?.connectedElement?.type)
  const isReadOnlyMultiStep = (isConnectedElement && isParentReadOnly) || (!isConnectedElement && multiStep.isReadOnly) // (multiStep.isReadOnly && !isConnectedElement);

  return (
    <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
      <div
        className={`${hideElement && !isEditMode ? 'hidden' : ''} ${data?.labelTop ? "" : "flex"}`}
      >
        <div 
          className={`${isLabelHidden ? 'hidden' : ''}`}
          style={labelStyle}
        >
          <InlineEditing
            initData={data}
            style={{...labelStyle, maxWidth: columnWidth}}
            classes={`sf-input-text-line-label flex text-sm text-gray-700 sm:mt-px sm:pt-2 break-words ${labelClass}`}
            name='label'
            id={`label-${data.id}`}
          >
            <label style={{ ...labelStyle, wordBreak: 'break-word' }} className={`sf-input-text-line-label flex text-sm text-gray-700 sm:mt-px sm:pt-2 break-words ${labelClass} ${alignmentClass}`}>
              {elementDesign.translateTerm(data.label)}
              {elementDesign.requiredFieldIndicator(data.requiredField)}
              {elementDesign.translateHelpText(data)}
            </label>
          </InlineEditing>
        </div>
        <div
          style={inputWrapperStyle}
          className={`sf-input-text-line-wrapper mt-2 sm:mt-0 w-full ${roundedCornerClass} ${inputErrorClasses} ${elementDesign.readOnlyElementClasses()}`}
        >
          <div className="relative flex" id={data.id}>
            {
              data?.leadingText &&
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">
                  {elementDesign.translateTerm(data.leadingText, 'leadingText')}
                </span>
              </div>
            }
            {
              data?.validateData === validateDataTypes.BBAN &&
              !data?.leadingText &&
              <SelectCountry inputId={data.id} setCountrySelect={setCountrySelect} countrySelect={countrySelect} inputRef={inputRef} />
            }
            { 
              data?.validateData === validateDataTypes.BBAN && 
              data?.leadingText && (
              <>
                <SelectCountry 
                  inputId={data.id}
                  setCountrySelect={setCountrySelect}
                  countrySelect={countrySelect}
                  inputRef={inputRef}
                />
                <div className={`relative flex items-center`}>
                  <div className="absolute pl-3 inset-y-0 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">
                      {elementDesign.translateTerm(data.leadingText, 'leadingText')}
                    </span>
                  </div>
                </div>
              </>
            )}
            <div className={`w-full`}>
              <input
                ref={inputRef}
                type={inputType}
                name={inputName}
                id={data.property}
                minLength={minLength || undefined}
                maxLength={maxLength || undefined}
                value={formContext ? formContext.inputValues[getDialogValueKey(data)]?.value : inputValue}
                placeholder={elementDesign.translateTerm(data.placeholder, 'placeholder')}
                onChange={onChange}
                onKeyDown={keyDownValidation}
                style={inputStyle}
                className={`sf-input-text-line-input px-3 inset-y-0 element block h-10 w-full ${leadingTextClass} ${trailingTextClass} ${roundedCornerClass} ${classPrefix} ${elementStyle.prefixClassName(classPrefix)}`}
                onBlur={() => elementDesign.handleBlur(formContext?.inputValues[getDialogValueKey(data)]?.value, inputType, countrySelect)}
                disabled={elementDesign.isReadOnly() || isReadOnlyMultiStep || isReadOnlyDependency || isParentReadOnly}
                {...inputProps}
              />
            </div>
            {
              data?.trailingText &&
              <div className={`absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3`}>
                <span className="text-gray-500 sm:text-sm">
                  {elementDesign.translateTerm(data.trailingText, 'trailingText')}
                </span>
              </div>
            }
          </div>
        </div>
        {elementDesign.errorMessage()}
      </div>
    </InlineActionbarElementWrapper>
  )
}
