import React from "react";
import { Select } from "./select";
import { Menu } from "@headlessui/react";
import { config } from "../../utils/config";
import { DotsHorizontalIcon, PencilAltIcon, PlusIcon } from "@heroicons/react/outline";
import { useProduct } from "../../hooks/useProduct";
import { workflowProductCode } from "../../utils/constants";

export const WorkflowSelect = ({ onChange, value, label, options, name }) => {
    const { hasProduct } = useProduct();
    const hasWorkflowAccess = hasProduct(workflowProductCode)
    return (
        <div className="flex flex-col justify-between">
            <span className="flex-grow flex flex-col" id="availability-label">
                <span className="px-4 sm:px-6 pt-3 text-sm font-medium text-gray-700">{label}</span>
            </span>
            <div className="flex space-x-1">
                <div className="flex-grow -mr-4">
                    <Select
                        onChange={onChange}
                        // label={label}
                        name={name}
                        value={value}
                        options={options}
                        disabled={!hasWorkflowAccess}

                    />
                </div>
                <div className="flex-none self-end relative pr-4 sm:pr-6">
                    <Menu>
                        <Menu.Button
                            disabled={!hasWorkflowAccess}
                            className="h-full px-2 py-2.5 flex justify-center items-center rounded border bg-white border-gray-300 shadow-sm hover:bg-gray-50"
                        >
                            <DotsHorizontalIcon
                                className="h-4 w-4"
                            />
                        </Menu.Button>
                        <Menu.Items
                            className="absolute right-0 -left-36 z-50 popover rounded border bg-white border-gray-300 text-gray-500 text-sm"
                        >
                            <Menu.Item
                            >
                                <div
                                    className="px-4 py-4 hover:bg-gray-100"
                                >
                                    <a
                                        href={config.workflow_ui_url + '?new=true'}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="flex"
                                    >
                                        <PlusIcon className="h-5 w-5 text-gray-600 mr-2" />
                                        <span>Create Workflow</span>
                                    </a>
                                </div>
                            </Menu.Item>
                            {!!value && (
                                <Menu.Item
                                >
                                    <div className="px-4 py-4 hover:bg-gray-100">
                                        <a
                                            href={config.workflow_ui_url + '/admin/workflow/editor/' + value}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex"
                                        >
                                            <PencilAltIcon className="h-5 w-5 text-gray-600 mr-2" />
                                            
                                            <span>Edit Workflow</span>

                                        </a>
                                    </div>
                                </Menu.Item>
                            )}
                        </Menu.Items>
                    </Menu>
                </div>
            </div>
        </div>
    )
}
