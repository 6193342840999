import { v4 as uuidv4 } from "uuid";

export const KYC3Address = () => {
  return {
      name: "KYC - 3 - Address",
      container: {
        id: uuidv4(),
        dialogObjectId: "67372f485b307343c4f49a78",
        lockToDialogObject: false,
        space: null,
        backgroundColor: "",
        margin: "20px 0",
        padding: null,
        borderWidth: null,
        borderColor: null,
        roundedCorners: false,
        static: false,
        name: "Contact information",
        newPage: false,
        backgroundImage: null,
        backgroundSize: 0,
        backgroundRepeat: false,
        avoidBreakInside: false,
        rows: [
          {
            id: uuidv4(),
            columns: [
              {
                id: uuidv4(),
                borderWidth: "0",
                elements: [
                  {
                    id: uuidv4(),
                    type: 0,
                    inputType: 0,
                    validateData: 0,
                    country: 0,
                    tableValueType: 0,
                    dynamicListValues: null,
                    video: null,
                    richTextValues: null,
                    bitmap: null,
                    layout: {
                      order: 2,
                      right: null
                    },
                    text: {
                      level: null,
                      alignment: "left",
                      fontFamily: "sans-serif",
                      size: 18,
                      bold: true,
                      italic: false,
                      underline: false,
                      color: "#000",
                      width: 0
                    },
                    labelStyle: null,
                    dependencies: null,
                    orderTable: null,
                    map: {
                      hideDropdown: false
                    },
                    customErrorMessage: null,
                    smartTable: null,
                    smartTableHeaders: [],
                    displayLabel: null,
                    valueLabel: null,
                    property: "KYC3-title-1-2",
                    value: "Address (where the company/association is registered)",
                    placeholder: null,
                    borderWidth: null,
                    borderColor: null,
                    width: null,
                    height: null,
                    label: null,
                    labelSetManually: false,
                    helpText: null,
                    leadingText: null,
                    trailingText: null,
                    labelTop: false,
                    backgroundColor: null,
                    roundedCorners: false,
                    defaultCheckedState: null,
                    isButton: null,
                    margin: null,
                    processStep: 0,
                    signatureProcess: null,
                    leadingIcon: null,
                    minValue: null,
                    maxValue: null,
                    minLength: null,
                    maxLength: null,
                    rows: null,
                    apiValidationId: null,
                    apiRetrieveId: null,
                    workflowRetrieveId: null,
                    workflowRetrieveLoadId: null,
                    options: null,
                    optionWithValueAlphabeticalOrder: false,
                    optionWithValues: null,
                    flowOptions: null,
                    preventDataInsert: false,
                    maxFileSizeInMb: null,
                    readOnly: false,
                    maxRows: null,
                    connectedParentElementId: null,
                    connectedElements: null,
                    initialValue: null,
                    lockRows: false,
                    requiredField: null,
                    requiredSignatures: [],
                    hideLabel: false,
                    orderTableValue: null,
                    hasAutomaticTranslationOff: false,
                    connectedElement: null,
                    resourceId: null,
                    restrictedFileTypes: null,
                    matrix: null,
                    isDynamicElement: false
                  }
                ],
                contentAlign: null,
                verticalAlign: null,
                backgroundColor: null,
                space: 3,
                span: null,
                margin: "0",
                padding: null,
                borderColor: null,
                roundedCorners: false,
                fullHeight: false
              }
            ],
            requiredRadioGroup: false,
            radioGroupName: null,
            space: 5,
            gap: null,
            backgroundColor: "#f5f5f5",
            margin: "5px 0",
            padding: "10px",
            borderWidth: "0",
            borderColor: null,
            roundedCorners: true,
            dynamicRowConfig: {
              enableRowRepeat: false,
              newRowRequiresAllFieldsToBeFilled: false
            }
          },
          {
            id: uuidv4(),
            columns: [
              {
                id: uuidv4(),
                borderWidth: "0",
                elements: [
                  {
                    id: uuidv4(),
                    type: 2,
                    inputType: 0,
                    validateData: 0,
                    country: 0,
                    tableValueType: 0,
                    dynamicListValues: null,
                    video: null,
                    richTextValues: null,
                    bitmap: null,
                    layout: {
                      order: 2,
                      right: null
                    },
                    text: {
                      level: null,
                      alignment: "left",
                      fontFamily: "sans-serif",
                      size: 16,
                      bold: false,
                      italic: false,
                      underline: false,
                      color: "#000",
                      width: 0
                    },
                    labelStyle: {
                      level: null,
                      alignment: "left",
                      fontFamily: "sans-serif",
                      size: 14,
                      bold: false,
                      italic: false,
                      underline: false,
                      color: "#000",
                      width: 0
                    },
                    dependencies: {
                      value: "none",
                      type: 0
                    },
                    orderTable: null,
                    map: {
                      hideDropdown: false
                    },
                    customErrorMessage: null,
                    smartTable: null,
                    smartTableHeaders: [],
                    displayLabel: null,
                    valueLabel: null,
                    property: "street",
                    value: "New input text line",
                    placeholder: null,
                    borderWidth: "1px",
                    borderColor: "#000",
                    width: null,
                    height: null,
                    label: "Street address",
                    labelSetManually: false,
                    helpText: null,
                    leadingText: null,
                    trailingText: null,
                    labelTop: true,
                    backgroundColor: "#fff",
                    roundedCorners: false,
                    defaultCheckedState: null,
                    isButton: null,
                    margin: null,
                    processStep: 0,
                    signatureProcess: null,
                    leadingIcon: null,
                    minValue: null,
                    maxValue: null,
                    minLength: null,
                    maxLength: null,
                    rows: null,
                    apiValidationId: null,
                    apiRetrieveId: null,
                    workflowRetrieveId: null,
                    workflowRetrieveLoadId: null,
                    options: null,
                    optionWithValueAlphabeticalOrder: false,
                    optionWithValues: null,
                    flowOptions: null,
                    preventDataInsert: false,
                    maxFileSizeInMb: null,
                    readOnly: false,
                    maxRows: null,
                    connectedParentElementId: null,
                    connectedElements: null,
                    initialValue: null,
                    lockRows: false,
                    requiredField: null,
                    requiredSignatures: [],
                    hideLabel: false,
                    orderTableValue: null,
                    hasAutomaticTranslationOff: false,
                    connectedElement: null,
                    resourceId: null,
                    restrictedFileTypes: null,
                    matrix: null,
                    isDynamicElement: false
                  },
                  {
                    id: uuidv4(),
                    type: 15,
                    inputType: 0,
                    validateData: 0,
                    country: 0,
                    tableValueType: 0,
                    dynamicListValues: null,
                    video: null,
                    richTextValues: null,
                    bitmap: null,
                    layout: {
                      order: 5,
                      right: null
                    },
                    text: {
                      level: null,
                      alignment: "left",
                      fontFamily: "sans-serif",
                      size: 16,
                      bold: false,
                      italic: false,
                      underline: false,
                      color: "#000",
                      width: 0
                    },
                    labelStyle: {
                      level: null,
                      alignment: "left",
                      fontFamily: "sans-serif",
                      size: 14,
                      bold: false,
                      italic: false,
                      underline: false,
                      color: "#000",
                      width: 0
                    },
                    dependencies: {
                      value: "none",
                      type: 0
                    },
                    orderTable: null,
                    map: {
                      hideDropdown: false
                    },
                    customErrorMessage: null,
                    smartTable: null,
                    smartTableHeaders: [],
                    displayLabel: null,
                    valueLabel: null,
                    property: "country",
                    value: null,
                    placeholder: "Nothing selected",
                    borderWidth: "1px",
                    borderColor: "#000",
                    width: null,
                    height: null,
                    label: "Country",
                    labelSetManually: true,
                    helpText: null,
                    leadingText: null,
                    trailingText: null,
                    labelTop: true,
                    backgroundColor: "#fff",
                    roundedCorners: false,
                    defaultCheckedState: null,
                    isButton: null,
                    margin: null,
                    processStep: 0,
                    signatureProcess: null,
                    leadingIcon: null,
                    minValue: null,
                    maxValue: null,
                    minLength: null,
                    maxLength: null,
                    rows: null,
                    apiValidationId: null,
                    apiRetrieveId: null,
                    workflowRetrieveId: null,
                    workflowRetrieveLoadId: null,
                    options: [
                      "Value 1",
                      "Value 2",
                      "Value 3",
                      "Value 4"
                    ],
                    optionWithValueAlphabeticalOrder: true,
                    optionWithValues: [
                      {
                        id: "08a952de-3115-4a3e-b207-23d79b05a076",
                        display: "Norway",
                        description: "Value 1",
                        value: "Norway"
                      },
                      {
                        id: "64387a20-43ba-484d-82d8-83b5870e2718",
                        display: "Sweden",
                        description: "Value 2",
                        value: "Sweden"
                      },
                      {
                        id: "a33d0423-04a8-49ff-9234-a0ce14cbc659",
                        display: "Denmark",
                        description: "Value 3",
                        value: "Denmark"
                      },
                      {
                        id: "2ceb4d24-1952-4ee5-87ec-069d09b1ef47",
                        display: "Finland",
                        description: "Value 4",
                        value: "Finalnd"
                      },
                      {
                        id: "ef5b363a-61d9-4264-ae52-8c9553bf6f3e",
                        display: " ",
                        description: "",
                        value: "1"
                      }
                    ],
                    flowOptions: null,
                    preventDataInsert: false,
                    maxFileSizeInMb: null,
                    readOnly: false,
                    maxRows: 10,
                    connectedParentElementId: null,
                    connectedElements: null,
                    initialValue: null,
                    lockRows: false,
                    requiredField: true,
                    requiredSignatures: [],
                    hideLabel: false,
                    orderTableValue: null,
                    hasAutomaticTranslationOff: false,
                    connectedElement: null,
                    resourceId: null,
                    restrictedFileTypes: null,
                    matrix: null,
                    isDynamicElement: false
                  },
                  {
                    id: uuidv4(),
                    type: 28,
                    inputType: 0,
                    validateData: 0,
                    country: 0,
                    tableValueType: 0,
                    dynamicListValues: null,
                    video: null,
                    richTextValues: null,
                    bitmap: null,
                    layout: null,
                    text: null,
                    labelStyle: null,
                    dependencies: null,
                    orderTable: null,
                    map: {
                      hideDropdown: false
                    },
                    customErrorMessage: null,
                    smartTable: null,
                    smartTableHeaders: [],
                    displayLabel: null,
                    valueLabel: null,
                    property: "spacer-17d9",
                    value: null,
                    placeholder: null,
                    borderWidth: null,
                    borderColor: null,
                    width: 10,
                    height: 33,
                    label: null,
                    labelSetManually: false,
                    helpText: null,
                    leadingText: null,
                    trailingText: null,
                    labelTop: false,
                    backgroundColor: null,
                    roundedCorners: false,
                    defaultCheckedState: null,
                    isButton: null,
                    margin: null,
                    processStep: 0,
                    signatureProcess: null,
                    leadingIcon: null,
                    minValue: null,
                    maxValue: null,
                    minLength: null,
                    maxLength: null,
                    rows: null,
                    apiValidationId: null,
                    apiRetrieveId: null,
                    workflowRetrieveId: null,
                    workflowRetrieveLoadId: null,
                    options: null,
                    optionWithValueAlphabeticalOrder: false,
                    optionWithValues: null,
                    flowOptions: null,
                    preventDataInsert: false,
                    maxFileSizeInMb: null,
                    readOnly: false,
                    maxRows: null,
                    connectedParentElementId: null,
                    connectedElements: null,
                    initialValue: null,
                    lockRows: false,
                    requiredField: null,
                    requiredSignatures: [],
                    hideLabel: false,
                    orderTableValue: null,
                    hasAutomaticTranslationOff: false,
                    connectedElement: null,
                    resourceId: null,
                    restrictedFileTypes: null,
                    matrix: null,
                    isDynamicElement: false
                  }
                ],
                contentAlign: null,
                verticalAlign: null,
                backgroundColor: null,
                space: 3,
                span: 2,
                margin: "0",
                padding: "0 20px 0 0",
                borderColor: null,
                roundedCorners: false,
                fullHeight: false
              },
              {
                id: uuidv4(),
                borderWidth: "0",
                elements: [
                  {
                    id: uuidv4(),
                    type: 2,
                    inputType: 0,
                    validateData: 0,
                    country: 0,
                    tableValueType: 0,
                    dynamicListValues: null,
                    video: null,
                    richTextValues: null,
                    bitmap: null,
                    layout: {
                      order: 2,
                      right: null
                    },
                    text: {
                      level: null,
                      alignment: "left",
                      fontFamily: "sans-serif",
                      size: 16,
                      bold: false,
                      italic: false,
                      underline: false,
                      color: "#000",
                      width: 0
                    },
                    labelStyle: {
                      level: null,
                      alignment: "left",
                      fontFamily: "sans-serif",
                      size: 14,
                      bold: false,
                      italic: false,
                      underline: false,
                      color: "#000",
                      width: 0
                    },
                    dependencies: {
                      value: "none",
                      type: 0
                    },
                    orderTable: null,
                    map: {
                      hideDropdown: false
                    },
                    customErrorMessage: null,
                    smartTable: null,
                    smartTableHeaders: [],
                    displayLabel: null,
                    valueLabel: null,
                    property: "postalcode",
                    value: "New input text line",
                    placeholder: null,
                    borderWidth: "1px",
                    borderColor: "#000",
                    width: null,
                    height: null,
                    label: "ZIP code",
                    labelSetManually: false,
                    helpText: null,
                    leadingText: null,
                    trailingText: null,
                    labelTop: true,
                    backgroundColor: "#fff",
                    roundedCorners: false,
                    defaultCheckedState: null,
                    isButton: null,
                    margin: null,
                    processStep: 0,
                    signatureProcess: null,
                    leadingIcon: null,
                    minValue: null,
                    maxValue: null,
                    minLength: null,
                    maxLength: null,
                    rows: null,
                    apiValidationId: null,
                    apiRetrieveId: null,
                    workflowRetrieveId: null,
                    workflowRetrieveLoadId: null,
                    options: null,
                    optionWithValueAlphabeticalOrder: false,
                    optionWithValues: null,
                    flowOptions: null,
                    preventDataInsert: false,
                    maxFileSizeInMb: null,
                    readOnly: false,
                    maxRows: null,
                    connectedParentElementId: null,
                    connectedElements: null,
                    initialValue: null,
                    lockRows: false,
                    requiredField: null,
                    requiredSignatures: [],
                    hideLabel: false,
                    orderTableValue: null,
                    hasAutomaticTranslationOff: false,
                    connectedElement: null,
                    resourceId: null,
                    restrictedFileTypes: null,
                    matrix: null,
                    isDynamicElement: false
                  }
                ],
                contentAlign: null,
                verticalAlign: null,
                backgroundColor: null,
                space: 0,
                span: null,
                margin: "0",
                padding: "0 20px 0 0",
                borderColor: null,
                roundedCorners: false,
                fullHeight: false
              },
              {
                id: uuidv4(),
                borderWidth: "0",
                elements: [
                  {
                    id: uuidv4(),
                    type: 2,
                    inputType: 0,
                    validateData: 0,
                    country: 0,
                    tableValueType: 0,
                    dynamicListValues: null,
                    video: null,
                    richTextValues: null,
                    bitmap: null,
                    layout: {
                      order: 2,
                      right: null
                    },
                    text: {
                      level: null,
                      alignment: "left",
                      fontFamily: "sans-serif",
                      size: 16,
                      bold: false,
                      italic: false,
                      underline: false,
                      color: "#000",
                      width: 0
                    },
                    labelStyle: {
                      level: null,
                      alignment: "left",
                      fontFamily: "sans-serif",
                      size: 14,
                      bold: false,
                      italic: false,
                      underline: false,
                      color: "#000",
                      width: 0
                    },
                    dependencies: {
                      value: "none",
                      type: 0
                    },
                    orderTable: null,
                    map: {
                      hideDropdown: false
                    },
                    customErrorMessage: null,
                    smartTable: null,
                    smartTableHeaders: [],
                    displayLabel: null,
                    valueLabel: null,
                    property: "postalcity",
                    value: "New input text line",
                    placeholder: "",
                    borderWidth: "1px",
                    borderColor: "#000",
                    width: null,
                    height: null,
                    label: "Place",
                    labelSetManually: true,
                    helpText: null,
                    leadingText: null,
                    trailingText: null,
                    labelTop: true,
                    backgroundColor: "#fff",
                    roundedCorners: false,
                    defaultCheckedState: null,
                    isButton: null,
                    margin: null,
                    processStep: 0,
                    signatureProcess: null,
                    leadingIcon: null,
                    minValue: null,
                    maxValue: null,
                    minLength: null,
                    maxLength: null,
                    rows: null,
                    apiValidationId: null,
                    apiRetrieveId: null,
                    workflowRetrieveId: null,
                    workflowRetrieveLoadId: null,
                    options: null,
                    optionWithValueAlphabeticalOrder: false,
                    optionWithValues: null,
                    flowOptions: null,
                    preventDataInsert: false,
                    maxFileSizeInMb: null,
                    readOnly: false,
                    maxRows: null,
                    connectedParentElementId: null,
                    connectedElements: null,
                    initialValue: "",
                    lockRows: false,
                    requiredField: null,
                    requiredSignatures: [],
                    hideLabel: false,
                    orderTableValue: null,
                    hasAutomaticTranslationOff: false,
                    connectedElement: null,
                    resourceId: null,
                    restrictedFileTypes: null,
                    matrix: null,
                    isDynamicElement: false
                  }
                ],
                contentAlign: null,
                verticalAlign: null,
                backgroundColor: null,
                space: 0,
                span: 2,
                margin: "0",
                padding: null,
                borderColor: null,
                roundedCorners: false,
                fullHeight: false
              }
            ],
            requiredRadioGroup: false,
            radioGroupName: null,
            space: 5,
            gap: null,
            backgroundColor: null,
            margin: "5px 0",
            padding: "0",
            borderWidth: "0",
            borderColor: null,
            roundedCorners: true,
            dynamicRowConfig: {
              enableRowRepeat: false,
              newRowRequiresAllFieldsToBeFilled: false
            }
          },
          {
            id: uuidv4(),
            columns: [
              {
                id: uuidv4(),
                borderWidth: "0",
                elements: [
                  {
                    id: uuidv4(),
                    type: 0,
                    inputType: 0,
                    validateData: 0,
                    country: 0,
                    tableValueType: 0,
                    dynamicListValues: null,
                    video: null,
                    richTextValues: null,
                    bitmap: null,
                    layout: {
                      order: 2,
                      right: null
                    },
                    text: {
                      level: null,
                      alignment: "left",
                      fontFamily: "sans-serif",
                      size: 18,
                      bold: true,
                      italic: false,
                      underline: false,
                      color: "#000",
                      width: 0
                    },
                    labelStyle: null,
                    dependencies: null,
                    orderTable: null,
                    map: {
                      hideDropdown: false
                    },
                    customErrorMessage: null,
                    smartTable: null,
                    smartTableHeaders: [],
                    displayLabel: null,
                    valueLabel: null,
                    property: "KYC3-1-s10a-title-1-1-1",
                    value: "Enter at least one contact information",
                    placeholder: null,
                    borderWidth: null,
                    borderColor: null,
                    width: null,
                    height: null,
                    label: null,
                    labelSetManually: false,
                    helpText: null,
                    leadingText: null,
                    trailingText: null,
                    labelTop: false,
                    backgroundColor: null,
                    roundedCorners: false,
                    defaultCheckedState: null,
                    isButton: null,
                    margin: null,
                    processStep: 0,
                    signatureProcess: null,
                    leadingIcon: null,
                    minValue: null,
                    maxValue: null,
                    minLength: null,
                    maxLength: null,
                    rows: null,
                    apiValidationId: null,
                    apiRetrieveId: null,
                    workflowRetrieveId: null,
                    workflowRetrieveLoadId: null,
                    options: null,
                    optionWithValueAlphabeticalOrder: false,
                    optionWithValues: null,
                    flowOptions: null,
                    preventDataInsert: false,
                    maxFileSizeInMb: null,
                    readOnly: false,
                    maxRows: null,
                    connectedParentElementId: null,
                    connectedElements: null,
                    initialValue: null,
                    lockRows: false,
                    requiredField: null,
                    requiredSignatures: [],
                    hideLabel: false,
                    orderTableValue: null,
                    hasAutomaticTranslationOff: false,
                    connectedElement: null,
                    resourceId: null,
                    restrictedFileTypes: null,
                    matrix: null,
                    isDynamicElement: false
                  }
                ],
                contentAlign: null,
                verticalAlign: null,
                backgroundColor: null,
                space: 3,
                span: null,
                margin: "0",
                padding: null,
                borderColor: null,
                roundedCorners: false,
                fullHeight: false
              }
            ],
            requiredRadioGroup: false,
            radioGroupName: null,
            space: 5,
            gap: null,
            backgroundColor: "#f5f5f5",
            margin: "5px 0",
            padding: "10px",
            borderWidth: "0",
            borderColor: null,
            roundedCorners: true,
            dynamicRowConfig: {
              enableRowRepeat: false,
              newRowRequiresAllFieldsToBeFilled: false
            }
          },
          {
            id: uuidv4(),
            columns: [
              {
                id: uuidv4(),
                borderWidth: "0",
                elements: [
                  {
                    id: uuidv4(),
                    type: 17,
                    inputType: 0,
                    validateData: 0,
                    country: 0,
                    tableValueType: 0,
                    dynamicListValues: null,
                    video: null,
                    richTextValues: null,
                    bitmap: null,
                    layout: {
                      order: 2,
                      right: null
                    },
                    text: {
                      level: null,
                      alignment: "left",
                      fontFamily: "arial",
                      size: 16,
                      bold: false,
                      italic: false,
                      underline: false,
                      color: "#000",
                      width: 0
                    },
                    labelStyle: {
                      level: null,
                      alignment: "left",
                      fontFamily: "arial",
                      size: 1,
                      bold: false,
                      italic: false,
                      underline: false,
                      color: "#6b7281",
                      width: 0
                    },
                    dependencies: null,
                    orderTable: null,
                    map: {
                      hideDropdown: false
                    },
                    customErrorMessage: null,
                    smartTable: null,
                    smartTableHeaders: [],
                    displayLabel: null,
                    valueLabel: "Phone",
                    property: "checkbox-314d-KYC3",
                    value: null,
                    placeholder: null,
                    borderWidth: "1px",
                    borderColor: null,
                    width: null,
                    height: null,
                    label: " ",
                    labelSetManually: true,
                    helpText: null,
                    leadingText: null,
                    trailingText: null,
                    labelTop: false,
                    backgroundColor: "#fff",
                    roundedCorners: false,
                    defaultCheckedState: false,
                    isButton: null,
                    margin: null,
                    processStep: 0,
                    signatureProcess: null,
                    leadingIcon: null,
                    minValue: null,
                    maxValue: null,
                    minLength: null,
                    maxLength: null,
                    rows: null,
                    apiValidationId: null,
                    apiRetrieveId: null,
                    workflowRetrieveId: null,
                    workflowRetrieveLoadId: null,
                    options: null,
                    optionWithValueAlphabeticalOrder: false,
                    optionWithValues: null,
                    flowOptions: null,
                    preventDataInsert: false,
                    maxFileSizeInMb: null,
                    readOnly: false,
                    maxRows: null,
                    connectedParentElementId: null,
                    connectedElements: [
                      {
                        id: uuidv4(),
                        type: 2,
                        inputType: 0,
                        validateData: 0,
                        country: 0,
                        tableValueType: 0,
                        dynamicListValues: null,
                        video: null,
                        richTextValues: null,
                        bitmap: null,
                        layout: {
                          order: 2,
                          right: null
                        },
                        text: {
                          level: null,
                          alignment: "left",
                          fontFamily: "sans-serif",
                          size: 16,
                          bold: false,
                          italic: false,
                          underline: false,
                          color: "#000",
                          width: 0
                        },
                        labelStyle: {
                          level: null,
                          alignment: "left",
                          fontFamily: "sans-serif",
                          size: 16,
                          bold: false,
                          italic: false,
                          underline: false,
                          color: "#000",
                          width: 0
                        },
                        dependencies: {
                          value: "none",
                          type: 0
                        },
                        orderTable: null,
                        map: {
                          hideDropdown: false
                        },
                        customErrorMessage: null,
                        smartTable: null,
                        smartTableHeaders: [],
                        displayLabel: null,
                        valueLabel: null,
                        property: "inputTextLine-db75",
                        value: "New input text line",
                        placeholder: null,
                        borderWidth: "1px",
                        borderColor: "#000",
                        width: 400,
                        height: null,
                        label: "",
                        labelSetManually: false,
                        helpText: null,
                        leadingText: null,
                        trailingText: null,
                        labelTop: true,
                        backgroundColor: "#fff",
                        roundedCorners: false,
                        defaultCheckedState: null,
                        isButton: null,
                        margin: null,
                        processStep: 0,
                        signatureProcess: null,
                        leadingIcon: null,
                        minValue: null,
                        maxValue: null,
                        minLength: null,
                        maxLength: null,
                        rows: null,
                        apiValidationId: null,
                        apiRetrieveId: null,
                        workflowRetrieveId: null,
                        workflowRetrieveLoadId: null,
                        options: null,
                        optionWithValueAlphabeticalOrder: false,
                        optionWithValues: null,
                        flowOptions: null,
                        preventDataInsert: false,
                        maxFileSizeInMb: null,
                        readOnly: false,
                        maxRows: null,
                        connectedParentElementId: "314d9df1-9335-4a90-b87c-a302351b0e94",
                        connectedElements: null,
                        initialValue: null,
                        lockRows: false,
                        requiredField: null,
                        requiredSignatures: [],
                        hideLabel: false,
                        orderTableValue: null,
                        hasAutomaticTranslationOff: false,
                        connectedElement: null,
                        resourceId: null,
                        restrictedFileTypes: null,
                        matrix: null,
                        isDynamicElement: false
                      }
                    ],
                    initialValue: null,
                    lockRows: false,
                    requiredField: null,
                    requiredSignatures: [],
                    hideLabel: true,
                    orderTableValue: null,
                    hasAutomaticTranslationOff: false,
                    connectedElement: null,
                    resourceId: null,
                    restrictedFileTypes: null,
                    matrix: null,
                    isDynamicElement: false
                  },
                  {
                    id: uuidv4(),
                    type: 17,
                    inputType: 0,
                    validateData: 0,
                    country: 0,
                    tableValueType: 0,
                    dynamicListValues: null,
                    video: null,
                    richTextValues: null,
                    bitmap: null,
                    layout: {
                      order: 2,
                      right: null
                    },
                    text: {
                      level: null,
                      alignment: "left",
                      fontFamily: "arial",
                      size: 16,
                      bold: false,
                      italic: false,
                      underline: false,
                      color: "#000",
                      width: 0
                    },
                    labelStyle: {
                      level: null,
                      alignment: "left",
                      fontFamily: "arial",
                      size: 1,
                      bold: false,
                      italic: false,
                      underline: false,
                      color: "#6b7281",
                      width: 0
                    },
                    dependencies: null,
                    orderTable: null,
                    map: {
                      hideDropdown: false
                    },
                    customErrorMessage: null,
                    smartTable: null,
                    smartTableHeaders: [],
                    displayLabel: null,
                    valueLabel: "Mobile phone",
                    property: "contactInformationMobile",
                    value: null,
                    placeholder: null,
                    borderWidth: "1px",
                    borderColor: null,
                    width: null,
                    height: null,
                    label: " ",
                    labelSetManually: true,
                    helpText: null,
                    leadingText: null,
                    trailingText: null,
                    labelTop: false,
                    backgroundColor: "#fff",
                    roundedCorners: false,
                    defaultCheckedState: false,
                    isButton: null,
                    margin: null,
                    processStep: 0,
                    signatureProcess: null,
                    leadingIcon: null,
                    minValue: null,
                    maxValue: null,
                    minLength: null,
                    maxLength: null,
                    rows: null,
                    apiValidationId: null,
                    apiRetrieveId: null,
                    workflowRetrieveId: null,
                    workflowRetrieveLoadId: null,
                    options: null,
                    optionWithValueAlphabeticalOrder: false,
                    optionWithValues: null,
                    flowOptions: null,
                    preventDataInsert: false,
                    maxFileSizeInMb: null,
                    readOnly: false,
                    maxRows: null,
                    connectedParentElementId: null,
                    connectedElements: [
                      {
                        id: uuidv4(),
                        type: 2,
                        inputType: 0,
                        validateData: 0,
                        country: 0,
                        tableValueType: 0,
                        dynamicListValues: null,
                        video: null,
                        richTextValues: null,
                        bitmap: null,
                        layout: {
                          order: 2,
                          right: null
                        },
                        text: {
                          level: null,
                          alignment: "left",
                          fontFamily: "sans-serif",
                          size: 16,
                          bold: false,
                          italic: false,
                          underline: false,
                          color: "#000",
                          width: 0
                        },
                        labelStyle: {
                          level: null,
                          alignment: "left",
                          fontFamily: "sans-serif",
                          size: 16,
                          bold: false,
                          italic: false,
                          underline: false,
                          color: "#000",
                          width: 0
                        },
                        dependencies: {
                          value: "none",
                          type: 0
                        },
                        orderTable: null,
                        map: {
                          hideDropdown: false
                        },
                        customErrorMessage: null,
                        smartTable: null,
                        smartTableHeaders: [],
                        displayLabel: null,
                        valueLabel: null,
                        property: "inputTextLine-ea2c",
                        value: "New input text line",
                        placeholder: null,
                        borderWidth: "1px",
                        borderColor: "#000",
                        width: 400,
                        height: null,
                        label: "",
                        labelSetManually: false,
                        helpText: null,
                        leadingText: null,
                        trailingText: null,
                        labelTop: true,
                        backgroundColor: "#fff",
                        roundedCorners: false,
                        defaultCheckedState: null,
                        isButton: null,
                        margin: null,
                        processStep: 0,
                        signatureProcess: null,
                        leadingIcon: null,
                        minValue: null,
                        maxValue: null,
                        minLength: null,
                        maxLength: null,
                        rows: null,
                        apiValidationId: null,
                        apiRetrieveId: null,
                        workflowRetrieveId: null,
                        workflowRetrieveLoadId: null,
                        options: null,
                        optionWithValueAlphabeticalOrder: false,
                        optionWithValues: null,
                        flowOptions: null,
                        preventDataInsert: false,
                        maxFileSizeInMb: null,
                        readOnly: false,
                        maxRows: null,
                        connectedParentElementId: "314d9df1-9335-4a90-b87c-a302351b0e94",
                        connectedElements: null,
                        initialValue: null,
                        lockRows: false,
                        requiredField: null,
                        requiredSignatures: [],
                        hideLabel: false,
                        orderTableValue: null,
                        hasAutomaticTranslationOff: false,
                        connectedElement: null,
                        resourceId: null,
                        restrictedFileTypes: null,
                        matrix: null,
                        isDynamicElement: false
                      }
                    ],
                    initialValue: null,
                    lockRows: false,
                    requiredField: null,
                    requiredSignatures: [],
                    hideLabel: true,
                    orderTableValue: null,
                    hasAutomaticTranslationOff: false,
                    connectedElement: null,
                    resourceId: null,
                    restrictedFileTypes: null,
                    matrix: null,
                    isDynamicElement: false
                  },
                  {
                    id: uuidv4(),
                    type: 17,
                    inputType: 0,
                    validateData: 0,
                    country: 0,
                    tableValueType: 0,
                    dynamicListValues: null,
                    video: null,
                    richTextValues: null,
                    bitmap: null,
                    layout: {
                      order: 2,
                      right: null
                    },
                    text: {
                      level: null,
                      alignment: "left",
                      fontFamily: "arial",
                      size: 16,
                      bold: false,
                      italic: false,
                      underline: false,
                      color: "#000",
                      width: 0
                    },
                    labelStyle: {
                      level: null,
                      alignment: "left",
                      fontFamily: "arial",
                      size: 1,
                      bold: false,
                      italic: false,
                      underline: false,
                      color: "#6b7281",
                      width: 0
                    },
                    dependencies: null,
                    orderTable: null,
                    map: {
                      hideDropdown: false
                    },
                    customErrorMessage: null,
                    smartTable: null,
                    smartTableHeaders: [],
                    displayLabel: null,
                    valueLabel: "E-mail",
                    property: "contactInformationEmail",
                    value: null,
                    placeholder: null,
                    borderWidth: "1px",
                    borderColor: null,
                    width: null,
                    height: null,
                    label: " ",
                    labelSetManually: true,
                    helpText: null,
                    leadingText: null,
                    trailingText: null,
                    labelTop: false,
                    backgroundColor: "#fff",
                    roundedCorners: false,
                    defaultCheckedState: false,
                    isButton: null,
                    margin: null,
                    processStep: 0,
                    signatureProcess: null,
                    leadingIcon: null,
                    minValue: null,
                    maxValue: null,
                    minLength: null,
                    maxLength: null,
                    rows: null,
                    apiValidationId: null,
                    apiRetrieveId: null,
                    workflowRetrieveId: null,
                    workflowRetrieveLoadId: null,
                    options: null,
                    optionWithValueAlphabeticalOrder: false,
                    optionWithValues: null,
                    flowOptions: null,
                    preventDataInsert: false,
                    maxFileSizeInMb: null,
                    readOnly: false,
                    maxRows: null,
                    connectedParentElementId: null,
                    connectedElements: [
                      {
                        id: uuidv4(),
                        type: 2,
                        inputType: 0,
                        validateData: 0,
                        country: 0,
                        tableValueType: 0,
                        dynamicListValues: null,
                        video: null,
                        richTextValues: null,
                        bitmap: null,
                        layout: {
                          order: 2,
                          right: null
                        },
                        text: {
                          level: null,
                          alignment: "left",
                          fontFamily: "sans-serif",
                          size: 16,
                          bold: false,
                          italic: false,
                          underline: false,
                          color: "#000",
                          width: 0
                        },
                        labelStyle: {
                          level: null,
                          alignment: "left",
                          fontFamily: "sans-serif",
                          size: 16,
                          bold: false,
                          italic: false,
                          underline: false,
                          color: "#000",
                          width: 400
                        },
                        dependencies: {
                          value: "none",
                          type: 0
                        },
                        orderTable: null,
                        map: {
                          hideDropdown: false
                        },
                        customErrorMessage: null,
                        smartTable: null,
                        smartTableHeaders: [],
                        displayLabel: null,
                        valueLabel: null,
                        property: "inputTextLine-95e1",
                        value: "New input text line",
                        placeholder: null,
                        borderWidth: "1px",
                        borderColor: "#000",
                        width: null,
                        height: null,
                        label: "",
                        labelSetManually: false,
                        helpText: null,
                        leadingText: null,
                        trailingText: null,
                        labelTop: true,
                        backgroundColor: "#fff",
                        roundedCorners: false,
                        defaultCheckedState: null,
                        isButton: null,
                        margin: null,
                        processStep: 0,
                        signatureProcess: null,
                        leadingIcon: null,
                        minValue: null,
                        maxValue: null,
                        minLength: null,
                        maxLength: null,
                        rows: null,
                        apiValidationId: null,
                        apiRetrieveId: null,
                        workflowRetrieveId: null,
                        workflowRetrieveLoadId: null,
                        options: null,
                        optionWithValueAlphabeticalOrder: false,
                        optionWithValues: null,
                        flowOptions: null,
                        preventDataInsert: false,
                        maxFileSizeInMb: null,
                        readOnly: false,
                        maxRows: null,
                        connectedParentElementId: "314d9df1-9335-4a90-b87c-a302351b0e94",
                        connectedElements: null,
                        initialValue: null,
                        lockRows: false,
                        requiredField: null,
                        requiredSignatures: [],
                        hideLabel: false,
                        orderTableValue: null,
                        hasAutomaticTranslationOff: false,
                        connectedElement: null,
                        resourceId: null,
                        restrictedFileTypes: null,
                        matrix: null,
                        isDynamicElement: false
                      }
                    ],
                    initialValue: null,
                    lockRows: false,
                    requiredField: null,
                    requiredSignatures: [],
                    hideLabel: true,
                    orderTableValue: null,
                    hasAutomaticTranslationOff: false,
                    connectedElement: null,
                    resourceId: null,
                    restrictedFileTypes: null,
                    matrix: null,
                    isDynamicElement: false
                  }
                ],
                contentAlign: null,
                verticalAlign: null,
                backgroundColor: null,
                space: 0,
                span: null,
                margin: "0",
                padding: null,
                borderColor: null,
                roundedCorners: false,
                fullHeight: false
              }
            ],
            requiredRadioGroup: false,
            radioGroupName: null,
            space: 5,
            gap: null,
            backgroundColor: null,
            margin: "5px 0",
            padding: "0",
            borderWidth: "0",
            borderColor: null,
            roundedCorners: true,
            dynamicRowConfig: {
              enableRowRepeat: false,
              newRowRequiresAllFieldsToBeFilled: false
            }
          },
          {
            id: uuidv4(),
            columns: [
              {
                id: uuidv4(),
                borderWidth: "0",
                elements: [
                  {
                    id: uuidv4(),
                    type: 28,
                    inputType: 0,
                    validateData: 0,
                    country: 0,
                    tableValueType: 0,
                    dynamicListValues: null,
                    video: null,
                    richTextValues: null,
                    bitmap: null,
                    layout: null,
                    text: null,
                    labelStyle: null,
                    dependencies: null,
                    orderTable: null,
                    map: {
                      hideDropdown: false
                    },
                    customErrorMessage: null,
                    smartTable: null,
                    smartTableHeaders: [],
                    displayLabel: null,
                    valueLabel: null,
                    property: "spacer-8d04",
                    value: null,
                    placeholder: null,
                    borderWidth: null,
                    borderColor: null,
                    width: 10,
                    height: 10,
                    label: null,
                    labelSetManually: false,
                    helpText: null,
                    leadingText: null,
                    trailingText: null,
                    labelTop: false,
                    backgroundColor: null,
                    roundedCorners: false,
                    defaultCheckedState: null,
                    isButton: null,
                    margin: null,
                    processStep: 0,
                    signatureProcess: null,
                    leadingIcon: null,
                    minValue: null,
                    maxValue: null,
                    minLength: null,
                    maxLength: null,
                    rows: null,
                    apiValidationId: null,
                    apiRetrieveId: null,
                    workflowRetrieveId: null,
                    workflowRetrieveLoadId: null,
                    options: null,
                    optionWithValueAlphabeticalOrder: false,
                    optionWithValues: null,
                    flowOptions: null,
                    preventDataInsert: false,
                    maxFileSizeInMb: null,
                    readOnly: false,
                    maxRows: null,
                    connectedParentElementId: null,
                    connectedElements: null,
                    initialValue: null,
                    lockRows: false,
                    requiredField: null,
                    requiredSignatures: [],
                    hideLabel: false,
                    orderTableValue: null,
                    hasAutomaticTranslationOff: false,
                    connectedElement: null,
                    resourceId: null,
                    restrictedFileTypes: null,
                    matrix: null,
                    isDynamicElement: false
                  },
                  {
                    id: uuidv4(),
                    type: 28,
                    inputType: 0,
                    validateData: 0,
                    country: 0,
                    tableValueType: 0,
                    dynamicListValues: null,
                    video: null,
                    richTextValues: null,
                    bitmap: null,
                    layout: null,
                    text: null,
                    labelStyle: null,
                    dependencies: null,
                    orderTable: null,
                    map: {
                      hideDropdown: false
                    },
                    customErrorMessage: null,
                    smartTable: null,
                    smartTableHeaders: [],
                    displayLabel: null,
                    valueLabel: null,
                    property: "spacer-91ee",
                    value: null,
                    placeholder: null,
                    borderWidth: null,
                    borderColor: null,
                    width: 10,
                    height: 30,
                    label: null,
                    labelSetManually: false,
                    helpText: null,
                    leadingText: null,
                    trailingText: null,
                    labelTop: false,
                    backgroundColor: null,
                    roundedCorners: false,
                    defaultCheckedState: null,
                    isButton: null,
                    margin: null,
                    processStep: 0,
                    signatureProcess: null,
                    leadingIcon: null,
                    minValue: null,
                    maxValue: null,
                    minLength: null,
                    maxLength: null,
                    rows: null,
                    apiValidationId: null,
                    apiRetrieveId: null,
                    workflowRetrieveId: null,
                    workflowRetrieveLoadId: null,
                    options: null,
                    optionWithValueAlphabeticalOrder: false,
                    optionWithValues: null,
                    flowOptions: null,
                    preventDataInsert: false,
                    maxFileSizeInMb: null,
                    readOnly: false,
                    maxRows: null,
                    connectedParentElementId: null,
                    connectedElements: null,
                    initialValue: null,
                    lockRows: false,
                    requiredField: null,
                    requiredSignatures: [],
                    hideLabel: false,
                    orderTableValue: null,
                    hasAutomaticTranslationOff: false,
                    connectedElement: null,
                    resourceId: null,
                    restrictedFileTypes: null,
                    matrix: null,
                    isDynamicElement: false
                  }
                ],
                contentAlign: null,
                verticalAlign: null,
                backgroundColor: null,
                space: 0,
                span: null,
                margin: "0",
                padding: null,
                borderColor: null,
                roundedCorners: false,
                fullHeight: false
              }
            ],
            requiredRadioGroup: false,
            radioGroupName: null,
            space: 5,
            gap: null,
            backgroundColor: null,
            margin: "5px 0",
            padding: "0",
            borderWidth: "0",
            borderColor: null,
            roundedCorners: true,
            dynamicRowConfig: {
              enableRowRepeat: false,
              newRowRequiresAllFieldsToBeFilled: false
            }
          },
          {
            id: uuidv4(),
            columns: [
              {
                id: uuidv4(),
                borderWidth: "0",
                elements: [
                  {
                    id: uuidv4(),
                    type: 0,
                    inputType: 0,
                    validateData: 0,
                    country: 0,
                    tableValueType: 0,
                    dynamicListValues: null,
                    video: null,
                    richTextValues: null,
                    bitmap: null,
                    layout: {
                      order: 2,
                      right: null
                    },
                    text: {
                      level: null,
                      alignment: "left",
                      fontFamily: "sans-serif",
                      size: 18,
                      bold: true,
                      italic: false,
                      underline: false,
                      color: "#000",
                      width: 0
                    },
                    labelStyle: null,
                    dependencies: null,
                    orderTable: null,
                    map: {
                      hideDropdown: false
                    },
                    customErrorMessage: null,
                    smartTable: null,
                    smartTableHeaders: [],
                    displayLabel: null,
                    valueLabel: null,
                    property: "HeadlinePerson-KYC3",
                    value: "Real principal and representative",
                    placeholder: null,
                    borderWidth: null,
                    borderColor: null,
                    width: null,
                    height: null,
                    label: null,
                    labelSetManually: false,
                    helpText: null,
                    leadingText: null,
                    trailingText: null,
                    labelTop: false,
                    backgroundColor: null,
                    roundedCorners: false,
                    defaultCheckedState: null,
                    isButton: null,
                    margin: null,
                    processStep: 0,
                    signatureProcess: null,
                    leadingIcon: null,
                    minValue: null,
                    maxValue: null,
                    minLength: null,
                    maxLength: null,
                    rows: null,
                    apiValidationId: null,
                    apiRetrieveId: null,
                    workflowRetrieveId: null,
                    workflowRetrieveLoadId: null,
                    options: null,
                    optionWithValueAlphabeticalOrder: false,
                    optionWithValues: null,
                    flowOptions: null,
                    preventDataInsert: false,
                    maxFileSizeInMb: null,
                    readOnly: false,
                    maxRows: null,
                    connectedParentElementId: null,
                    connectedElements: null,
                    initialValue: null,
                    lockRows: false,
                    requiredField: null,
                    requiredSignatures: [],
                    hideLabel: false,
                    orderTableValue: null,
                    hasAutomaticTranslationOff: false,
                    connectedElement: null,
                    resourceId: null,
                    restrictedFileTypes: null,
                    matrix: null,
                    isDynamicElement: false
                  }
                ],
                contentAlign: null,
                verticalAlign: null,
                backgroundColor: null,
                space: 3,
                span: null,
                margin: "0",
                padding: null,
                borderColor: null,
                roundedCorners: false,
                fullHeight: false
              }
            ],
            requiredRadioGroup: false,
            radioGroupName: null,
            space: 5,
            gap: null,
            backgroundColor: "#f5f5f5",
            margin: "5px 0",
            padding: "10px",
            borderWidth: "0",
            borderColor: null,
            roundedCorners: true,
            dynamicRowConfig: {
              enableRowRepeat: false,
              newRowRequiresAllFieldsToBeFilled: false
            }
          },
          {
            id: uuidv4(),
            columns: [
              {
                id: uuidv4(),
                borderWidth: "0",
                elements: [
                  {
                    id: uuidv4(),
                    type: 15,
                    inputType: 0,
                    validateData: 0,
                    country: 0,
                    tableValueType: 0,
                    dynamicListValues: null,
                    video: null,
                    richTextValues: null,
                    bitmap: null,
                    layout: {
                      order: 5,
                      right: null
                    },
                    text: {
                      level: null,
                      alignment: "left",
                      fontFamily: "sans-serif",
                      size: 16,
                      bold: false,
                      italic: false,
                      underline: false,
                      color: "#000",
                      width: 0
                    },
                    labelStyle: {
                      level: null,
                      alignment: "left",
                      fontFamily: "sans-serif",
                      size: 16,
                      bold: false,
                      italic: false,
                      underline: false,
                      color: "#000",
                      width: 0
                    },
                    dependencies: {
                      value: "checkbox-314d-1-1",
                      type: 0
                    },
                    orderTable: null,
                    map: {
                      hideDropdown: false
                    },
                    customErrorMessage: null,
                    smartTable: null,
                    smartTableHeaders: [],
                    displayLabel: null,
                    valueLabel: null,
                    property: "NumberOfPaticipals",
                    value: null,
                    placeholder: "<none>",
                    borderWidth: "1px",
                    borderColor: "#000",
                    width: null,
                    height: null,
                    label: "Does the company/association have one or more real principals?",
                    labelSetManually: true,
                    helpText: null,
                    leadingText: null,
                    trailingText: null,
                    labelTop: true,
                    backgroundColor: "#fff",
                    roundedCorners: false,
                    defaultCheckedState: null,
                    isButton: null,
                    margin: null,
                    processStep: 0,
                    signatureProcess: null,
                    leadingIcon: null,
                    minValue: null,
                    maxValue: null,
                    minLength: null,
                    maxLength: null,
                    rows: null,
                    apiValidationId: null,
                    apiRetrieveId: null,
                    workflowRetrieveId: null,
                    workflowRetrieveLoadId: null,
                    options: [
                      "Value 1",
                      "Value 2",
                      "Value 3",
                      "Value 4"
                    ],
                    optionWithValueAlphabeticalOrder: false,
                    optionWithValues: [
                      {
                        id: uuidv4(),
                        display: "None",
                        description: "Value 1",
                        value: "0"
                      },
                      {
                        id: uuidv4(),
                        display: "1 principals",
                        description: "Value 2",
                        value: "1"
                      },
                      {
                        id: uuidv4(),
                        display: "2 principals",
                        description: "Value 3",
                        value: "1-2"
                      },
                      {
                        id: uuidv4(),
                        display: "3 principals",
                        description: "Value 4",
                        value: "1-2-3"
                      },
                      {
                        id: uuidv4(),
                        display: "4 principals",
                        description: "",
                        value: "1-2-3-4"
                      },
                      {
                        id: uuidv4(),
                        display: "5 principals",
                        description: "",
                        value: "1-2-3-4-5"
                      }
                    ],
                    flowOptions: null,
                    preventDataInsert: false,
                    maxFileSizeInMb: null,
                    readOnly: false,
                    maxRows: 10,
                    connectedParentElementId: null,
                    connectedElements: null,
                    initialValue: null,
                    lockRows: false,
                    requiredField: null,
                    requiredSignatures: [],
                    hideLabel: false,
                    orderTableValue: null,
                    hasAutomaticTranslationOff: false,
                    connectedElement: null,
                    resourceId: null,
                    restrictedFileTypes: null,
                    matrix: null,
                    isDynamicElement: false
                  }
                ],
                contentAlign: null,
                verticalAlign: null,
                backgroundColor: null,
                space: 0,
                span: null,
                margin: "0",
                padding: null,
                borderColor: null,
                roundedCorners: false,
                fullHeight: false
              }
            ],
            requiredRadioGroup: false,
            radioGroupName: null,
            space: 5,
            gap: null,
            backgroundColor: null,
            margin: "5px 0",
            padding: "0 2px 0 0",
            borderWidth: "0",
            borderColor: null,
            roundedCorners: true,
            dynamicRowConfig: {
              enableRowRepeat: false,
              newRowRequiresAllFieldsToBeFilled: false
            }
          }
        ],
        showLogicControl: [],
        hasAutomaticTranslationOff: false,
        resourceId: null,
        readOnly: null
      }
    }
}